$game-primary-text-color: white;
$game-secondary-text-color: black;
$game-primary-disabled-text-color: white;
$game-primary-text-translation-color: black;
$game-text1-color: #fda947;
$game-text2-color: #ddd;

$color1: #34c1dc;
$color2: white;
$color3: #ffd186;
$color4: #cff6ff;
$color5: #01778b;
$color6: #b69590;
$color7: #ffd186;
$color8: #fda947;
$color9: red;
$color10: #ffb7ab;
$color11: #d68029; // hover de back3
$color12: #6d3a2f; // hover de back3
$color13: black;
$color14: #005a58; // hover de back5
$color15: #0030a4; // hover de back5

$color16: #fec207; // playground border / balloons stan border
$color17: #fff7d6; // playground background
$color18: #490d08; // playground title background
$color19: #723004; // timerline background
$color20: #db732b; // timerline slider
$color21: #ffe0c8; // anagram letter background
$color22: #df8f73; // balloons stan background color
$color23: #a7e842; // correct
$color24: #b27e5b; // shadow of 19
$color25: #ed908f; // border of wordsearch
$color26: #f9e3aa; // playground results background
$color27: #16af0c;
$color28: #cc7a1f;
$color29: #e25656;
$color30: #f5c443;
$color31: #91181d;
