@use './colors';

.playground__hangman {
	position: relative;

	&__audio__support {
		box-sizing: inherit;
		position: absolute;
		top: 0;
		left: 0;
		&__button {
			cursor: pointer;
			width: 50px;
			height: 50px;
			padding: 5px;
			border: 1px solid colors.$color5;
			border-radius: 6px;
			margin: 0.3rem;
			background-color: colors.$color2;
			&:hover {
				background-color: colors.$color4;
			}
			&__image {
				width: 100%;
				height: 100%;
				max-width: 60px;
				max-height: 60px;
			}
		}
	}

	&__section {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
		height: 100%;
		&__top {
			flex: 1;
			height: 0;
			display: flex;
			justify-content: space-evenly;

			&__button_panel {
				background: transparent;
				border: none;
				outline: none;
				cursor: pointer;
				position: absolute;
				top: 0%;
				left: -1%;
				width: 50px;
				height: 50px;

				&__hidden {
					visibility: hidden;
				}

				& > img {
					width: 100%;
					height: 100%;
					display: block;
					aspect-ratio: 1;
				}
			}

			&__one {
				flex: 1;
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 0.5rem;
			}
		}
		&__secret__word {
			font-size: 1.5rem;
			line-height: 1;
			width: max-content;
			display: flex;
			gap: 0.1rem;
			&__animation {
				&__incorrect {
					animation: secretWordIncorrectAnimation 500ms ease 2
						forwards;
				}
				&__completed {
					animation: secretWordCompletedAnimation 1000ms ease forwards;
				}
				&__game__over {
					animation: secretWordGameOverAnimation 1000ms ease forwards;
				}
			}
			&__container {
				width: 1.5rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 0.2rem;
			}
			&__letter {
				font-weight: bold;
				color: transparent;
				&__animation__founded {
					animation: secretLetterFoundedAnimation 700ms ease forwards;
				}
			}
			&__underline {
				width: 100%;
				height: 0.3rem;
				border-radius: 0.5rem;
				background-color: colors.$color19;
			}
		}
		&__letter__options__section {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			&__grid {
				box-sizing: border-box;
				display: grid;
				grid-template-columns: repeat(9, 1fr);
				grid-template-rows: repeat(
					2,
					calc(calc(0.75rem + 1.5vw) + 0.5rem)
				);
				grid-gap: calc(0.2rem + 0.3rem);
				width: 100%;
				&__letter__option {
					overflow: auto;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: clamp(1rem, 0.75rem + 1.5vw, 1.5rem);
					border-radius: 6px;
					border: 2px solid colors.$color19;
					color: colors.$color19;
					background-color: colors.$color21;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					cursor: default;
					transition: background-color 250ms ease-in;
					&__active:hover {
						transition: none;
						color: colors.$game-secondary-text-color;
						background-color: colors.$color8;
						border: colors.$color2 solid 2px;
						cursor: pointer;
					}
					&__disabled {
						background-color: colors.$color6;
						color: colors.$game-primary-disabled-text-color;
						border-color: transparent;
					}
				}
			}
		}
	}
}

@keyframes secretWordIncorrectAnimationDEPRECATED {
	0% {
		border: 2px solid colors.$color6;
		background: colors.$color6;
	}
	50% {
		border: 2px solid colors.$color9;
		background: colors.$color10;
	}
	100% {
		border: 2px solid colors.$color6;
		background: colors.$color6;
	}
}

@keyframes secretWordCompletedAnimation {
	0% {
		background: colors.$color6;
	}
	33% {
		background: colors.$color8;
	}
	66% {
		background: colors.$color6;
	}
	100% {
		background: colors.$color8;
	}
}

@keyframes secretWordGameOverAnimation {
	0% {
		border: 2px solid colors.$color6;
		background: colors.$color6;
	}
	33% {
		border: 2px solid colors.$color9;
		background: colors.$color10;
	}
	66% {
		border: 2px solid colors.$color6;
		background: colors.$color6;
	}
	100% {
		border: 2px solid colors.$color9;
		background: colors.$color10;
	}
}

@keyframes secretLetterFoundedAnimation {
	0% {
		color: transparent;
	}
	33% {
		color: colors.$color19;
	}
	66% {
		color: transparent;
	}
	100% {
		color: colors.$color19;
	}
}
