@use './utils';

.playground__start {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;

	& > button {
		@extend .main__button;
		color: white;

		font-size: 1.1rem;
		width: max-content;
		padding: 0 2.5rem;
	}
}
