@use './colors';
@use './vars';

.config {
	width: 400px;
	padding: 1rem;
	height: 100%;

	&__main {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.2rem;

		&__button {
			&:last-of-type {
				// background-color: red;
				margin-bottom: auto;
			}
			cursor: pointer;
			font-size: 1.1rem;
			font-weight: bold;

			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 2.5rem;
			border: none;
			border-radius: 10px;

			background-color: colors.$color18;
			color: colors.$color2;
		}
	}

	&__sound {
		display: flex;
		flex-direction: column;
		overflow: auto;

		&__section {
			display: flex;
			align-items: center;
			padding: 1rem 0.5rem;
			border-bottom: 1px solid colors.$color19;
			font-weight: 500;

			&:first-child {
				border-top: 1px solid colors.$color19;
			}

			&__text {
				flex: 1;
			}

		}
		
		&__btn {
			background: #480d0a;
			border: none;
			width: 100%;
			height: 100%;
			border-radius: 15px;
			padding: .7rem;
			font-size: 1.25rem;
			color: #fff;
			font-weight: 600;
			cursor: pointer;
		}
	}

	&_delete_account {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2em;
		margin-top: 2em;

		&_text {
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			text-align: center;
			font-size: 20px;
			text-wrap: pretty;
		}

		&_button {
			text-decoration: none;
			padding: .7em 2.2em;
			background: linear-gradient(#f2bd82, #f18c28);
			border: 2px solid #db8421;
			border-radius: 15px;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			font-weight: 700;
			font-size: 20px;
			color: #fff;
		}
	}
}

.controlVolume {
	display: flex;
	align-items: center;
	gap: .5em;

	& img {
		width: 40px;
		height: 40px;
		display: block;
		aspect-ratio: 1;
	}

	& > label {
		display: flex;
		align-items: center;
		gap: .5em;

		& > input {
			-webkit-appearance: none;
			appearance: none;
			max-width: 100px;
			height: 8px;
			border-radius: 15px;
			cursor: pointer;

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				width: 15px;
				height: 15px;
				background: vars.$colorBrownApp;
				border-radius: 50%;
			}
		}

		& > span {
			width: 40px;
		}
	}
}
