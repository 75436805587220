@use '../vars';

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba($color: vars.$color11, $alpha: .2);
}

.spinner {
  box-sizing: border-box;

  width: 50px;
  height: 50px;

  border-radius: 50%;
  border: 4px solid grey;
  border-bottom-color: transparent;

  animation: spinnerSpinning 800ms linear infinite;

  // ----- CONTAINER OPTIONS
  &Page {
    display: flex;
    justify-content: center;
    width: 100%;
  
    padding-top: 1.5rem;
  }
  &Center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

@keyframes spinnerSpinning {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
