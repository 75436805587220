@use './colors';

// word with image
.playground__wwi {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	&__section {
		flex: 1;
		display: flex;
		gap: 1rem;
		&__options {
			flex: 0.5;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 0.5rem;
			overflow: auto;
		}
		&__image {
			flex: 1;
			display: flex;
			flex-direction: column;
			transition: all 250ms ease-in;
			max-width: 55%;
			&__animation {
				&__visible {
					opacity: 1;
				}
				&__invisible {
					opacity: 0;
				}
			}
		}
	}
	&__card {
		font-size: 1rem;
		box-sizing: border-box;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem 0;
		border-radius: 6px;
		background-color: colors.$color21;
		overflow: hidden;
		transition: opacity 250ms ease-in-out,
			background-color 250ms ease-in-out;
		&__selectable {
			font-weight: bold;
			background-color: colors.$color21;
			color: colors.$color19;
			cursor: pointer;
			&:hover {
				transition: none;
				background-color: colors.$color8;
			}
		}
		&__not__selectable {
			border: 1px solid #ddd;
			opacity: 0.8;
			color: #666;
			background-color: white;
		}
		&__selected {
			border: 1px solid black;
			background-color: colors.$color8;
		}
		&__invisible {
			border: 1px solid black;
			opacity: 0.2;
		}
		&__wrong {
			border: 1px solid black;
			animation: WordWithImageCardWrongAnimation 200ms 2 ease-out;
		}
		&__completed {
			opacity: 0.5;
			animation: WordWithImageCardCompletedAnimation 500ms ease-out;
		}
		&__disabled {
			background-color: grey;
			opacity: 0.6;
		}
		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&__translation {
				text-align: center;
				font-size: 1rem;
			}
		}
	}
}

@keyframes WordWithImageCardWrongAnimation {
	0% {
		transform: rotateZ(0deg);
	}
	25% {
		transform: rotateZ(-8deg);
	}
	50% {
		transform: rotateZ(0deg);
	}
	75% {
		transform: rotateZ(8deg);
	}
	100% {
		transform: rotateZ(0deg);
	}
}

@keyframes WordWithImageCardCompletedAnimation {
	0% {
		transform: translateY(0px);
	}
	25% {
		transform: translateY(-20px);
	}
	50% {
		transform: translateY(0px);
	}
	75% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0px);
	}
}
