@use './colors';
@use './utils';
@use './vars';
$playground-button-size: 32px;
$bar-height: 2.5rem;

.playground {
	overflow: hidden;
	box-sizing: border-box;
	width: 95%;
	max-width: 1100px;
	min-width: 400px;
	height: 85%;
	max-height: 850px;
	border-radius: 15px;

	&__game {
		// EACH GAME HAS THIS ADITIONAL CLASS :D
		width: 100%;
		height: 100%;
	}
	&__right {
		padding: 1rem;
		padding-top: 2rem;
		background-color: colors.$color16;
		width: 100%;
		height: 100%;
	}
	&__main {
		position: relative;
		width: 100%;
		height: 100%;

		border-radius: 15px;

		display: flex;
		flex-direction: column;
		gap: 1rem;

		padding: 1rem;
		padding-top: 0;

		background-color: colors.$color17;

		&__bar {
			z-index: 3;
			position: relative;
			height: $bar-height;
			margin-top: calc(calc($bar-height / 3) * -1);
			display: flex;
			justify-content: center;
			align-items: center;
			// background-color: teal;

			&__title {
				font-size: 1.5rem;
				height: 100%;
				padding: 0 1.5rem;

				display: flex;
				justify-content: center;
				align-items: center;

				border-radius: 15px;

				background-color: colors.$color18;
				color: colors.$game-primary-text-color;
			}

			&__buttons {
				position: absolute;
				top: 0;
				right: 0;
				margin-right: -1.5rem;

				display: flex;
				gap: 1rem;
				&__info {
					cursor: pointer;
					width: $bar-height;
					height: $bar-height;
					background-color: transparent;
					border: none;
					background-image: url(../assets/icons/helpPlayground.png);
					background-size: cover;
				}
				&__close {
					cursor: pointer;
					width: $bar-height;
					height: $bar-height;
					background-color: transparent;
					border: none;
					background-image: url(../assets/icons/closePlayground.png);
					background-size: cover;
				}
			}

			&__container__crossword {
				display: flex;
				align-items: center;
				max-width: 250px;
				gap: 1em;

				& > p {
					text-align: center;
					font-weight: 700;
				}

				&__container {
					display: flex;
					flex-direction: column;

					& > img {
						place-self: center;
						transform: rotate(90deg);
						box-shadow: -3px 3px 3px 0 #000 inset;
					}

					& > picture {
						display: flex;

						& > img:nth-child(1) {
							box-shadow: -3px -3px 2px 0 #000 inset;
						}
						
						& > img:nth-child(2) {
							transform: rotate(-90deg);
							box-shadow: 3px -3px 3px 0 #000 inset;
						}

						& > img:nth-child(3) {
							transform: rotate(180deg);
							box-shadow: 3px 3px 3px 0 #000 inset;
						}
					}

					& img {
						width: 30px;
						height: 30px;
						display: block;
						aspect-ratio: 1;
						background: #ff9350;
						padding: 3px;
						border-radius: 3px;
					}
				}
			}
		}
		&__game {
			flex: 1;
			position: relative;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 0;

			&__animation {
				@extend .animation;
				width: 100%;
				height: 100%;
				overflow: auto;
				padding: .5em;

					&::-webkit-scrollbar-thumb {
						color: vars.$colorBrownApp;
						background: vars.$colorBrownApp;
				}
				&__show {
					@extend .animation__show;
				}
			}
		}
	}
}

@media (orientation: portrait) {
}
