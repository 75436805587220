@use './colors';
$border-width: 5px;

.storyteller {
	position: relative;
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: rgba($color: #000000, $alpha: 0.7);

	&__animations {
		&__close {
			animation: closeAnimation 250ms ease-out forwards;
		}
	}
	&__space {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&__back {
			&__border__left {
				width: calc(calc(100vw / 2) - calc(78ch / 2) + 5ch);
			}
			&__main {
				width: 0px;
				background-color: rgba($color: white, $alpha: 1);
				display: flex;
				flex-direction: column;
				justify-content: end;
				align-items: center;
				& > img {
					width: 400px;
					height: 600px;
				}
			}
			&__border__right {
				flex: 1;
			}
		}
		&__front {
			&__border__left {
				flex: 1;
			}
			&__main {
				width: 700px;
				max-width: 65ch;
				display: flex;
				flex-direction: column;
				justify-content: end;
				align-items: center;
			}
			&__border__right {
				flex: 1;
			}
		}
	}
	&__card {
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		width: 100%;
		margin-bottom: 1.5rem;
		&__bar {
			display: flex;
			align-items: center;

			margin-left: 2rem;
			margin-bottom: 5px;

			& > div {
				font-weight: bold;
				font-size: 1.2rem;

				margin-right: auto;
				padding: 0.5rem 2rem;

				border-radius: 26px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border: $border-width solid colors.$color30;

				background-color: colors.$color31;
				color: colors.$color30;
			}
			& > button {
				cursor: pointer;
				font-weight: bold;
				font-size: 1rem;
				padding: 2px;
				margin-left: 1rem;
				border: none;
				border-radius: 5px;
				outline: 2px solid #91181d;
				background: linear-gradient(#e8702d, #f6d879);

				& > div {
					padding: 0.3rem 1.5rem;
					border-radius: 5px;
					background: linear-gradient(
						#f6d879 10%,
						#f1b531 50%,
						#e8702d
					);
					color: #8c2201;

					&:hover {
						opacity: 0.9;
					}
				}
			}
		}
		&__content {
			overflow: auto;
			background-color: #eee;
			display: flex;
			flex-direction: column;
			align-items: center;

			max-height: 300px;
			min-height: 100px;

			padding: .5rem 1.5rem .5rem 1.5rem;
			border-radius: 16px;
			border: $border-width solid colors.$color30;

			outline: $border-width solid colors.$color31;
			background-color: #5f3030;

			&::-webkit-scrollbar {
				background: gray;
			}

			&::-webkit-scrollbar-thumb {
				background: black;
				border-radius: 20px;
			}

			&__text {
				width: 100%;
				text-align: left;
				color: white;
				&__animations {
					&__fade {
						animation: dynamicTextFade 400ms ease-out forwards;
					}
				}
			}

			&__tsoundActionbuext {
				color: #fff;
				font-size: 1.1em;
			}
		}
	}

	@keyframes closeAnimation {
		to {
			opacity: 0;
		}
	}

	@keyframes dynamicTextFade {
		from {
			transform: translateX(0px);
			opacity: 1;
		}
		to {
			transform: translateX(-100px);
			opacity: 0;
		}
	}
}
