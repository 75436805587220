@import 'variables';

.bodyError{
        background-image: url(../assets/images/Error404/Fondoespacio.svg);
        background-size: cover;
    .error{
        @extend .center;
        height: 100vh;
        &__container{
            &__title{
                width: 350px;
                &__img{
                    width: 100%;
                }
            }
            &__btn{
                @extend .btn__purpple;
            }
        }
    }
}

