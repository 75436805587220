@use './colors';

.timerline {
	box-sizing: border-box;
	display: flex;
	width: 100%;

	&__container {
		box-sizing: inherit;
		width: 50%;
		margin: auto;
		height: 1rem;
		overflow: hidden;
		border-radius: 1rem;
		background-color: colors.$color19;

		&__slider {
			box-sizing: inherit;
			position: relative;
			height: 100%;
			background: colors.$color20;
			left: 0;
			&__animation {
				&__decrease {
					animation: timerDecrease 40000ms linear forwards;
				}
			}
		}
	}
}

.positionTopFixed {
	margin-bottom: 1rem;
}
