@use './colors';

.containerWordSearch {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
}

.wordSearchMatrix {
	display: flex;

	&Button {
		display: flex;
		justify-content: center;
		align-items: center;
		color: grey;
		font-weight: bold;
		cursor: pointer;
		width: 3em;
		height: 3em;
		background-color: #ffe0c8;
		border: 1px solid #ed908f;
		border-radius: 2px;
		user-select: none;
	}
}

.playground__word__search {

	&__main {
		flex: 1;
		align-self: stretch;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1.5rem;
	}

	&__words {
		overflow: auto;
		width: max-content;
		height: max-content;
		min-width: min-content;
		align-self: stretch;
		display: flex;
		align-items: start;
		flex-direction: column;
		gap: 1rem;

		padding: 1rem 1rem;
		border-radius: 2px;
		border: 2px solid colors.$color26;
		border-bottom: 8px solid colors.$color26;

		background-color: rgba($color: colors.$color26, $alpha: 0.6);

		&__element {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			&__text {
				width: min-content;
				font-size: 1.1rem;
				font-weight: bold;
				color: colors.$color18;

				&__completed {
					position: relative;
					font-weight: normal;
					opacity: 0.3;

					&:after {
						content: '';
						display: block;
						width: 110%;
						height: 50%;
						position: absolute;
						top: 10%;
						left: -5%;
						border-bottom: 3px solid;
					}
				}
			}
		}
	}
}

@keyframes scaleSquare {
	from {
		transform: scale(1);
	}
	
	to {
		transform: scale(1.2);
	}
}
