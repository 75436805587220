@import './colors';

.loading__screen {
	position: fixed;

	width: 100vw;
	height: 100vh;

	background-color: $color4;

	&__background {
		width: 100vw;
		height: 100vh;

		&__image {
			width: 100%;
			height: 100%;

			background-image: url('../assets/images/backgroundLoading2.avif');
			background-image: image-set(
				url('../assets/images/backgroundLoading2.avif') type("image/avif"),
				url('../assets/images/backgroundLoading2.png') type("image/png"),
			);
			background-size: cover;
			background-position: center;
		}
		&__teodoro {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			right: 0;

			width: 100%;
			height: 100%;

			& > img {
				position: relative;
				left: 12%;
				max-width: 100%;
				max-height: 90%;
			}
		}
	}
	&__main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&__spinner {
			width: 100%;
			height: 100px;

			position: absolute;
			bottom: 0rem;
			right: 1rem;
			color: white;

			display: flex;
			justify-content: center;
			align-items: end;
			padding: 1rem;

			&__wings {
				width: 100%;
				height: 100%;

				@keyframes spinWings {
					from {
						transform: rotateZ(0deg);
					}
					to {
						transform: rotateZ(360deg);
					}
				}

				animation: spinWings 2000ms linear infinite forwards;

				& > img {
					max-width: 100%;
					height: auto;
				}
			}
			&__head {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;

				display: flex;
				justify-content: center;
				align-items: center;

				& > img {
					width: 120%;
					height: 120%;
				}
			}
		}
	}
	&__progress__bar__container {
		// inside main
		box-sizing: inherit;
		overflow: hidden;
		position: relative;

		width: 80%;
		max-width: 800px;
		height: 20px;

		border-radius: 15px;
		border: 3px solid $color5;
		background-color: black;

		&__slider {
			position: absolute;

			top: 0;
			left: -5%;

			width: 100%;
			height: 100%;

			border-radius: 15px;
			background-color: $color5;
		}
		&__text {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $game-secondary-text-color;
		}
	}
	&__text {
		color: $game-secondary-text-color;
		text-align: center;
		margin: 0 2rem;
		max-width: 400px;
	}
}
