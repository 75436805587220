@import 'variables';

.editorBtnActionAdd {
	padding: 10px 20px;
	background-color: #fff;
	color: #3498db;
	border: 2px solid #3498db;
	font-size: 16px;
	font-weight: bold;
	&:hover {
		cursor: pointer;
		background-color: #3498db;
		color: #fff;
	}
}

.editorBtnActionDeleteWithoutIcon {
	padding: 10px 20px;
	background-color: #fff;
	color: #ff0000;
	border: 2px solid #ff0000;
	font-size: 16px;
	font-weight: bold;
	&:hover {
		cursor: pointer;
		background-color: #ff0000;
		color: #fff;
	}
}

.editorBtnActionAddActive {
	padding: 10px 20px;
	background-color: #3498db;
	color: #fff;
	border: 2px solid #3498db;
	font-size: 16px;
	font-weight: bold;
	&:hover {
		cursor: pointer;
		background-color: #fff;
		color: #3498db;
	}
}

.editorBtnActionBankActive {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #40b67f;
	color: #fff;
	border: 2px solid #40b67f;
	border-radius: 5px;
	text-align: center !important;
	margin-top: 5px;
	margin-left: 20px;
	width: 150px;
	transition: all 0.2s;
	height: 50px;
	font-size: 16px;
	font-weight: bold;
	&:hover {
		cursor: pointer;
		background-color: #fff;
		color: #40b67f;
	}
	.pIcons {
		margin: 0 10px 0 0;
	}
}

.editorBtnActionDelete {
	padding: 5px 10px;
	background-color: #fff;
	color: #3498db;
	border: none;
	font-size: 20px;
	font-weight: bold;
	background-color: transparent;
	&:hover {
		cursor: pointer;
		transform: translateY(-5px);
		background-color: transparent;
		p {
			color: #ff0000;
		}
	}

	p {
		margin: 0px;
		color: #ff0000b7;
	}
}

.editorBtnActionEdit {
	padding: 5px 10px;
	background-color: #fff;
	color: #3498db;
	border: none;
	font-size: 20px;
	font-weight: bold;
	background-color: transparent;
	&:hover {
		cursor: pointer;
		transform: translateY(-5px);
		background-color: transparent;
		p {
			color: #ffe600;
		}
	}

	p {
		margin: 0px;
		color: #e4d339c5;
	}
}

.editorBtnActionExchange {
	padding: 5px 10px;
	background-color: #fff;
	color: #3498db;
	border: none;
	font-size: 20px;
	font-weight: bold;
	background-color: transparent;
	&:hover {
		cursor: pointer;
		transform: translateY(-5px);
		background-color: transparent;
		p {
			color: #40ff00;
		}
	}

	p {
		margin: 0px;
		color: #40ff00;
	}
}

.animationContent {
	&__container {
		display: flex;
		flex-wrap: wrap;
		&__Left {
			width: 60%;
			overflow-x: scroll;
			img {
				max-height: 300px;
			}
		}
		&__Right {
			padding: 10px;
			width: 40%;
			&__Info {
				// background-color: aqua;
				h4 {
					margin-top: 10px;
				}
				audio {
					margin-top: 5px;
				}
			}
		}

		@media (max-width: 860px) {
			&__Left {
				width: 100%;
				overflow-x: scroll;
			}
			&__Right {
				width: 100%;
				&__Info {
					audio {
						width: 200px;
						height: 50px;
						background-color: #eee;
						border: 1px solid #ccc;
						padding: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 20px;
					}
				}
			}
		}
	}
}

.collapseNpcScene {
	margin-top: 10px;
	&__btnNpcs {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	&__btnAdd {
		@media (max-width: 860px) {
			display: grid;
			grid-template-columns: 1fr;
			text-align: center;
			.boxBtnAdd {
				text-align: center !important;
				.btnAdd {
				}
			}
		}
		width: 100%;
		margin: auto;
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		align-items: center;
		h1 {
			font-weight: bold !important;
			font-size: 25px;
			text-transform: uppercase;
		}
		.boxBtnAdd {
			text-align: end;
			.btnAdd {
				margin-top: auto;
				margin-bottom: auto;
			}
		}
	}
	&__Content {
		margin-top: 10px;
		display: flex;
		gap: 5px;
		flex-direction: column;
	}
	&__DeleteButton {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.ExtendManageExternal {
	width: 100% !important;
	padding-top: 20px;
}

.adminD {
	padding: 10px;
	&__title {
		h1 {
			color: rgb(14, 30, 53);
			text-shadow: 1px 1px 1px rgb(14, 30, 53);
			font-size: 25px;
			margin: auto;
			text-align: end;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}

	.contentErrorPerms {
		margin-top: 100px;
		text-align: center;
		.iconErrorPerms {
			color: #c00d0db0;
			font-size: 5rem;
			margin: auto;
		}
		.textErrorPerms {
			color: #c00d0db0;
			margin: auto;
			font-size: 2rem;
		}
	}

	.adminDesign {
		display: flex;
		flex-wrap: wrap;

		&__menu {
			margin-top: 15px;
			height: min-content !important;
			border: 2px solid #cac8c8bb;
			text-align: center;
			width: 19.5%;
			&__back {
			}
			&__title {
				h2 {
					margin-top: 10px;
					color: rgb(14, 30, 53);
					font-weight: 500 !important;
					text-shadow: 1px 1px 1px rgb(14, 30, 53);
				}
			}
			&__links {
				margin-top: 10px;
				display: grid;
				grid-template-columns: 1fr;
				justify-items: center;
				padding: 10px;

				&__containerButtons {
					margin-top: 10px;
					margin-bottom: 10px;

					width: 100%;

					display: flex;
					justify-content: end;
					align-items: end;
					flex-direction: column;
				}
				&__btns {
					width: 100%;
					margin-top: 10px;
					margin-bottom: 10px;

					&__btnLevelLanguage {
						width: 100%;
						margin-top: 5px;
						padding: 10px 20px;
						background-color: #fff;
						color: #3498db;
						border: 2px solid #3498db;
						font-size: 16px;
						font-weight: bold;
						&:hover {
							cursor: pointer;
							background-color: #3498db;
							color: #fff;
						}
					}

					&__btnLevelLanguageActive {
						width: 90%;
						margin-top: 5px;
						padding: 10px 20px;
						background-color: #3498db;
						color: #fff;
						border: 2px solid #3498db;
						transition: all 0.2s;
						font-size: 16px;
						font-weight: bold;
						&:hover {
							cursor: pointer;
							background-color: #fff;
							color: #3498db;
						}
					}
				}
				.active {
					width: 200px;
					margin-top: 10px;
					margin-bottom: 10px;
					@extend .btnAdd;
					background: #6799b6;
					width: 90;
					height: 50px;
					border: 2px dashed #2a50638c;
					box-shadow: 0px 0px 5px rgb(141, 136, 136);
					text-shadow: 0px 0px 1px rgb(255, 255, 255);
				}
			}

			&__btnStorebox {
				margin: 10px 0px 10px 0px;
				padding: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;

				// padding: 10px;

				&__btnItemBank {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #fff;
					color: #40b67f;
					border: 2px solid #40b67f;
					text-align: center !important;
					margin-top: 5px;
					width: 100%;
					transition: all 0.2s;
					height: 50px;
					font-size: 16px;
					font-weight: bold;
					&:hover {
						cursor: pointer;
						background-color: #40b67f;
						color: #fff;
					}
					.pIcons {
						margin: 0 10px 0 0;
					}
				}

				&__btnItemBankActive {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #40b67f;
					color: #fff;
					border: 2px solid #40b67f;
					text-align: center !important;
					margin-top: 5px;
					margin-left: 20px;
					width: 90%;
					transition: all 0.2s;
					height: 50px;
					font-size: 16px;
					font-weight: bold;
					&:hover {
						cursor: pointer;
						background-color: #fff;
						color: #40b67f;
					}
					.pIcons {
						margin: 0 10px 0 0;
					}
				}
			}
		}
		hr {
			margin: 0px 10px 0px 10px;
		}

		&__container {
			margin-top: 15px;
			border: 2px solid #cac8c8bb;
			width: 80%;
			margin-left: 5px;

			.bodyCollapseComponent {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 5px;
				&__description {
					display: flex;
					flex-wrap: wrap;
					border: 2px solid #cac8c8bb;
					width: 95%;
					margin-top: 5px;
					padding: 5px;
					&__value {
						width: 80%;
						p {
							margin-top: 5px;
							text-align: justify;
						}
					}
					&__options {
						width: 20%;
						align-items: center;
						text-align: end !important;
					}
					@media (max-width: 860px) {
						&__value {
							width: 100%;
						}
						&__options {
							width: 100%;
							text-align: center !important;
						}
					}
				}
				&__content {
					width: 95%;
					margin-top: 15px;
					margin-bottom: 15px;
					&__btnAdd {
						@media (max-width: 860px) {
							display: grid;
							grid-template-columns: 1fr;
							text-align: center;
							.boxBtnAdd {
								text-align: center !important;
							}
						}
						width: 100%;
						margin: auto;
						display: grid;
						grid-template-columns: 1fr 1fr;
						justify-content: center;
						align-items: center;
						h1 {
							color: rgb(14, 30, 53);
							font-weight: bold !important;
							font-size: 25px;
						}
						.boxBtnAdd {
							text-align: end;
							.btnAdd {
								margin-top: auto;
								margin-bottom: auto;
							}
						}
					}
					&__containerWorlds {
						margin-top: 20px;

						.adminComponents {
							width: 100%;
							margin-top: 5px;
							margin-bottom: 5px;
							.worldsBodyComponent {
								display: flex;
								flex-wrap: wrap;

								&__Left {
									width: 40%;
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									&__Image {
										img {
											margin-top: 5px;
											max-height: 130px;
											border-radius: 2px;
										}
									}
									&__IconQuestion {
										margin-top: 5px;
										display: flex;
										justify-content: center;
										align-items: center;
										border: 2px dashed #000;
										padding: 10px;
										margin: 5px;
										label {
											font-size: 50px;
										}
									}
								}

								&__Right {
									width: 60%;
									display: flex;
									justify-content: center;
									align-items: center;
									flex-direction: column;

									button {
										margin-top: 10px;
									}
								}

								&__Content {
									width: 100%;

									&__ContainerSceneWorld {
										margin-top: 20px;

										&__ContainerTitle {
											width: 100%;
											margin: auto;
											display: grid;
											grid-template-columns: 1fr 1fr;
											justify-content: center;
											align-items: center;
											h1 {
												color: rgb(14, 30, 53);
												font-weight: bold !important;
												font-size: 25px;
											}

											&__BoxButton {
												text-align: end;
											}
										}

										&__ListSceneWorld {
											margin-top: 30px;

											&__Item {
												.sceneWorlds {
													display: grid;
													grid-template-columns: 1fr 3fr;
													gap: 10px;
													border: 2px solid #cac8c8bb;
													padding: 5px;
													&__background {
														display: grid;
														text-align: center;
														border: 2px solid
															#cac8c8bb;
														p {
															border: 2px solid
																#cac8c8bb;
															background-color: white;
															border-radius: 10px;
															width: min-content;
															margin: auto;
															margin-top: 10px;
															margin-bottom: 10px;
															text-align: center;
															padding: 10px;
														}
														img {
															margin-top: 20px;
															width: 90% !important;
															border-radius: 2px;
														}
													}
													&__data {
														&__name {
															width: 100%;
															display: grid;
															grid-template-columns: 7fr 1fr;
															align-items: center;
															&__title {
																h2 {
																	color: rgb(
																		14,
																		30,
																		53
																	);
																	font-weight: bold !important;
																	font-size: 20px;
																	text-align: start;
																	text-transform: uppercase;
																}
															}
														}

														&__soundandcollision {
															display: grid;
															grid-template-columns: 1fr 1fr;
															gap: 10px;
															&__sound {
																h2 {
																	margin-top: 5px;
																	font-size: 18px;
																}
																audio {
																	margin-top: 10px;
																}
																p {
																	margin-top: 10px;
																}
															}
															&__collision {
																h2 {
																	margin-top: 5px;
																	font-size: 18px;
																}
																&__collisionfile {
																	margin-top: 10px;
																}
															}
														}

														&__edition {
															&__title {
																h2 {
																	margin-top: 5px;
																	font-size: 18px;
																	margin-bottom: 10px;
																}
															}
															&__contentButtons {
																width: 50%;
																display: flex;
																flex-direction: column;
																gap: 10px;
															}
														}
													}

													@media (max-width: 860px) {
														display: grid;
														grid-template-columns: 1fr;
														&__data {
															&__name {
																display: grid;
																grid-template-columns: 1fr;
															}
															&__soundandcollision {
																display: grid;
																grid-template-columns: 1fr;
																&__sound {
																	audio {
																		width: 200px;
																		height: 50px;
																		background-color: #eee;
																		border: 1px
																			solid
																			#ccc;
																		padding: 10px;
																		display: flex;
																		align-items: center;
																		justify-content: space-between;
																		font-size: 20px;
																	}
																}
															}
															&__edition {
																&__contentButtons {
																	width: 100%;
																}
															}
														}
													}
												}
											}
										}
									}

									&__ContainerSubWorld {
										margin-top: 20px;

										&__ContainerTitle {
											width: 100%;
											margin: auto;
											display: grid;
											grid-template-columns: 1fr 1fr;
											justify-content: center;
											align-items: center;
											h1 {
												color: rgb(14, 30, 53);
												font-weight: bold !important;
												font-size: 25px;
											}

											&__BoxButton {
												text-align: end;
											}
										}

										&__ListSubWorld {
											margin-top: 30px;
										}
									}
								}

								@media (max-width: 860px) {
									&__Left {
										width: 100%;
									}
									&__Right {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}

			.storeAdmin {
				&__buttons {
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 10px;
					.active {
						width: 70%;
						margin-top: 10px;
						margin-bottom: 10px;
						@extend .btnAdd;
						background: #6799b6;
						border: 2px dashed #2a50638c;
						box-shadow: 0px 0px 5px rgb(141, 136, 136);
						text-shadow: 0px 0px 1px rgb(255, 255, 255);
					}
					.btnAdd {
						margin: auto;
						width: 70%;
					}
				}
				&__container {
					margin-top: 20px;
					padding: 10px;
					border: 2px solid #cac8c8bb;
					border-radius: 10px;
					h1 {
						color: rgb(14, 30, 53);
						font-weight: 400 !important;
						font-size: 2.5rem;
						text-align: center;
					}
					&__btnAdd {
						display: grid;
						grid-template-columns: 1fr;
						align-items: center;
						.boxBtnAdd {
							text-align: center;
						}
					}

					.categories {
						margin-top: 10px;
						border: 2px solid #cac8c8bb;
						&__nameAndOptions {
							background-color: #f2f2f2d0;
							display: grid;
							grid-template-columns: 4fr 1fr;
							align-items: center;
							padding: 5px;
							border-bottom: 2px solid #cac8c8bb;
							.nameAndUsable {
								display: flex;
								align-items: center;
								h1 {
									margin-left: 10px;
									text-align: start;
									font-weight: 300;
									font-size: 20px;
									color: #15222e;
								}
								.circleOn {
									color: #40b67f;
								}
								.circleOff {
									color: #a12f2f;
								}
							}
							&__options {
								text-align: end !important;
							}
						}
						&__description {
							padding: 10px;
							text-align: center;
							p {
								font-size: 15px;
							}
						}
					}
					.itemsAdmin {
						width: 95%;
						height: auto;
						margin: auto;
						margin-top: 5px;
						&__nameAndOptions {
							@media (max-width: 1100px) {
								grid-template-columns: 1fr;
							}
							background-color: #f2f2f2d0;
							display: grid;
							grid-template-columns: 4fr 1fr;
							align-items: center;
							border-bottom: 2px solid #cac8c8bb;
							.nameAndUsable {
								display: flex;
								align-items: center;
								@media (max-width: 1100px) {
									margin: auto;
									text-align: center !important;
								}
								h1 {
									margin-left: 25px;
									margin-right: 10px;
									text-align: start;
									font-weight: 300;
									font-size: 20px;
								}
								.circleOn {
									color: #40b67f;
								}
								.circleOff {
									color: #a12f2f;
								}
							}
							&__options {
								@media (max-width: 1100px) {
									text-align: center !important;
								}
								margin-top: 10px;
								text-align: end !important;
								.btnDelete {
									font-size: 1.5rem !important;
									p {
										margin: 0px;
										color: #c00d0db0;
									}
									&:hover {
										transform: translateY(-5px);
										background-color: transparent;
									}
								}
								.btnEdit {
									font-size: 1.5rem !important;
									p {
										margin: 0px;
										color: #e4d339;
									}
									&:hover {
										transform: translateY(-5px);
										background-color: transparent;
									}
								}
							}
						}
						&__category {
							text-align: center;
							p {
								font-size: 15px;
								text-align: center;
							}
							h1 {
								font-size: 20px;
								font-weight: bold !important;
								margin-bottom: 10px;
							}
						}
						&__media {
							@media (max-width: 1100px) {
								grid-template-columns: 1fr;
							}
							margin-top: 30px;
							margin-bottom: 30px;
							display: grid;
							grid-template-columns: 1fr 1fr;
							justify-content: center;
							h3 {
								font-size: 20px;
								font-weight: bold !important;
								margin-bottom: 10px;
							}
							.img {
								text-align: center;
								display: grid;
								justify-content: center;
								margin: auto;
								img {
									border: 2px solid #cac8c8bb;
									border-radius: 10px;
									margin: auto;
									width: 60%;
								}
							}
							.imgPrev {
								text-align: center;
								display: grid;
								justify-content: center;
								margin: auto;
								img {
									margin: auto;
									border: 2px solid #cac8c8bb;
									border-radius: 10px;
									width: 60%;
								}
							}
						}
					}
				}
			}

			&__mainContainerBank {
				display: flex;
				justify-content: center;
				align-items: center;
				&__Item {
					width: 95%;
					&__title {
						margin-top: 10px;
						font-size: 12px;
					}
					&__content {
						hr {
							margin-top: 5px;
							margin-bottom: 5px;
						}

						&__List {
							margin-top: 20px;
							display: flex;
							flex-direction: column;
							gap: 5px;
						}
					}
				}
			}
		}

		.subWorld {
			margin-top: 5px;
			&__component {
				&__description {
					margin-bottom: 10px;
				}
				&__scene {
					&__ContainerSceneSubWorld {
						margin-top: 30px;
						&__ContainerTitle {
							width: 100%;
							margin: auto;
							display: grid;
							grid-template-columns: 1fr 1fr;
							justify-content: center;
							align-items: center;
							h1 {
								color: rgb(14, 30, 53);
								font-weight: bold !important;
								font-size: 25px;
							}

							&__BoxButton {
								text-align: end;
							}
						}
						&__ListSceneSubWorld {
							margin-top: 30px;

							&__Item {
								margin-top: 10px;
								.sceneSubWorlds {
									display: grid;
									grid-template-columns: 1fr 3fr;
									gap: 10px;
									border: 2px solid #cac8c8bb;
									padding: 5px;
									&__background {
										display: grid;
										text-align: center;
										border: 2px solid #cac8c8bb;
										p {
											border: 2px solid #cac8c8bb;
											background-color: white;
											border-radius: 10px;
											width: min-content;
											margin: auto;
											margin-top: 10px;
											margin-bottom: 10px;
											text-align: center;
											padding: 10px;
										}
										img {
											margin-top: 20px;
											width: 90% !important;
											border-radius: 2px;
										}
									}
									&__data {
										&__name {
											width: 100%;
											display: grid;
											grid-template-columns: 7fr 1fr;
											align-items: center;
											&__title {
												h2 {
													color: rgb(14, 30, 53);
													font-weight: bold !important;
													font-size: 20px;
													text-align: start;
													text-transform: uppercase;
												}
											}
										}

										&__soundandcollision {
											display: grid;
											grid-template-columns: 1fr 1fr;
											gap: 10px;
											&__sound {
												h2 {
													margin-top: 5px;
													font-size: 18px;
												}
												audio {
													margin-top: 10px;
												}
												p {
													margin-top: 10px;
												}
											}
											&__collision {
												h2 {
													margin-top: 5px;
													font-size: 18px;
												}
												&__collisionfile {
													margin-top: 10px;
												}
											}
										}

										&__edition {
											&__title {
												h2 {
													margin-top: 5px;
													font-size: 18px;
													margin-bottom: 10px;
												}
											}
											&__contentButtons {
												width: 100%;
												display: flex;
												gap: 10px;
											}

											@media (max-width: 860px) {
												&__contentButtons {
													flex-direction: column;
												}
											}
										}
									}

									@media (max-width: 860px) {
										display: grid;
										grid-template-columns: 1fr;
										&__data {
											&__name {
												display: grid;
												grid-template-columns: 1fr;
											}
											&__soundandcollision {
												display: grid;
												grid-template-columns: 1fr;
												&__sound {
													audio {
														width: 200px;
														height: 50px;
														background-color: #eee;
														border: 1px solid #ccc;
														padding: 10px;
														display: flex;
														align-items: center;
														justify-content: space-between;
														font-size: 20px;
													}
												}
											}
											&__edition {
												&__contentButtons {
													width: 100%;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&__initialDescription {
			margin-top: 10px;
			text-align: center;
			width: 78%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media (max-width: 1020px) {
			&__container {
				width: 79%;
			}
		}

		@media (max-width: 860px) {
			&__menu {
				width: 100%;
			}
			&__container {
				width: 100%;
			}
		}
	}

	.starsContent {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		&__Container {
			width: 100%;
			border: 2px solid #a7a9aa8c;
			padding: 10px;
			margin-bottom: 5px;
			display: flex;
			flex-wrap: wrap;
			&__Left {
				width: 40%;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					max-width: 90%;
					max-height: 150px;
				}
				audio {
					margin-top: 30px;
				}
			}
			&__Right {
				width: 60%;
				&__Options {
					display: flex;
					justify-content: end;
				}
			}

			@media (max-width: 860px) {
				&__Left {
					width: 100%;
				}
				&__Right {
					width: 100%;
					&__Options {
						justify-content: center;
					}
				}
			}
		}
	}

	.containerNpcState {
		display: flex;
		justify-content: center;
		align-items: center;

		.collapseNpc {
			@media (max-width: 1200px) {
				width: auto;
			}
			margin-top: 10px;
			width: 85%;
			&__button {
				text-align: start;
				grid-template-columns: 1fr;
				&__title {
					h2 {
						margin-left: 40px;
						font-weight: 500;
						font-size: 20px;
					}
				}
			}
			&__container {
				grid-template-columns: 1fr;
				&__infoNpc {
					margin: 10px;
					&__int {
						.img {
							width: 180px;
							display: grid;
							display: grid;
							justify-content: center;
							margin: auto;
							img {
								margin: auto;
								width: 2000px;
							}
						}
						.text {
							text-align: left !important;
							margin: 8px;
						}
					}
				}
				&__viewImages {
					.viewTitle {
						text-align: center;
						font-size: 1.1rem;
					}
					.viewImgs {
						font-size: 1rem;
						display: grid;
						justify-content: center;
						justify-items: center;
						align-content: center;
						align-items: center;
						text-align: center;
						grid-template-columns: 1fr;
						margin-bottom: 20px;
						img {
							width: 50%;
						}
					}
				}
			}
		}
	}

	nav {
		width: 200px;
		margin-bottom: 2px;
		font-size: 18px;
	}

	nav ul {
		width: 80%;
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin: 0;
		list-style: none;
	}

	nav ul li {
		flex: 1;
		margin-right: 10px;
		text-align: center;
		transition: background-color 0.3s ease-in-out;
		cursor: pointer;
	}

	nav ul li:last-child {
		margin-right: 0;
	}

	nav ul li.active {
		background: #8ec7e9;
		border: 1px solid #67808d;
		border-bottom: none;
		color: #fff;
	}

	nav ul li span {
		display: block;
		padding: 10px;
		text-decoration: none;
		color: #333;
	}

	nav ul li a:hover {
		text-decoration: none;
		color: #272727;
	}

	nav ul li.active a:hover {
		color: #fff;
	}
	.nav-content {
		padding-top: 10px;
		border: 1px solid #a9abaca9;
		border-bottom: none;
		border-left: none;
		border-right: none;
		.npcDataContent {
			&__btnAdd {
				@media (max-width: 1100px) {
					display: grid;
					grid-template-columns: 1fr !important;
					text-align: center;
					margin-bottom: 20px;
					.boxBtnAdd {
						text-align: center !important;
						.btnAdd {
						}
					}
				}
				width: auto;
				margin: auto;
				display: grid;
				grid-template-columns: 1fr 1fr !important;
				justify-content: center;
				align-items: center;
				h1 {
					color: rgb(14, 30, 53);
					font-weight: bold !important;
					font-size: 25px;
					text-transform: uppercase;
					font-style: italic;
					text-align: start;
				}
				.boxBtnAdd {
					text-align: end;
					.btnAdd {
						margin-top: auto;
						margin-bottom: auto;
					}
				}
			}
			&__data {
				margin-top: 5px !important;
				grid-template-columns: 1fr;
				border: 2px solid #a7a9aa8c;
				padding: 5px;
				.nameAndOptions {
					@media (max-width: 1100px) {
						display: grid;
						grid-template-columns: 1fr;
						h1 {
							text-align: center !important;
						}
						.options {
							margin: auto !important;
						}
					}
					border-bottom: 2px solid #a7a9aa8c;
					display: grid;
					grid-template-columns: 1fr 1fr;
					h1 {
						color: rgb(14, 30, 53);
						font-weight: bold !important;
						font-size: 25px;
						text-align: start;
					}
					.options {
						text-align: end !important;
					}
				}
				.description {
					p {
						color: rgb(14, 30, 53);
						font-weight: 300 !important;
						font-size: 15px;
					}
				}
			}
		}
	}

	.npcList {
		background-color: white;
		padding: 10px;

		.contentErrorPerms {
			margin-top: 100px;
			text-align: center;
			.iconErrorPerms {
				color: #c00d0db0;
				font-size: 5rem;
				margin: auto;
			}
			.textErrorPerms {
				color: #c00d0db0;
				margin: auto;
				font-size: 2rem;
			}
		}

		&__titleNPCS {
			margin-top: 30px;
			text-align: center;
			font-weight: bold;
			font-size: 25px;
			text-transform: uppercase;
			color: #15222e;
		}
		&__btnAdd {
			margin-top: 20px;
			display: grid;
			align-items: center;
			justify-content: center;
			.btnAddNpc {
				background-color: #8ec7e9;
				color: white;
				width: 100px;
				height: 50px;
				border: 2px solid #52acda8c;
				border-radius: 5px;
				transition: all 0.2s ease-in-out;
				font-family: 'League Spartan', sans-serif;
				font-size: 1.3rem;
				font-weight: 300;
				&:hover {
					background: #a0d7f7;
					width: 90;
					height: 50px;
					border-radius: 10%;
					border: 2px dashed #52acda8c;
					box-shadow: 0px 0px 5px rgb(141, 136, 136);
					text-shadow: 0px 0px 1px rgb(255, 255, 255);
				}
			}
		}
		&__containerList {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		.adminComponents {
			@media (max-width: 1200px) {
				width: auto;
			}
			font-weight: 400 !important;
			width: 85%;
			margin: auto;
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}

	.npcContent {
		&__container {
			display: flex;
			flex-wrap: wrap;

			&__Left {
				width: 60%;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					max-height: 400px;
				}
			}
			&__Right {
				width: 40%;
				h2 {
					margin-bottom: 20px;
				}
			}

			@media (max-width: 860px) {
				&__Left {
					width: 100%;
				}
				&__Right {
					width: 100%;
				}
			}

			&__data {
				@media (max-width: 1200px) {
					display: grid;
					grid-template-columns: 1fr !important;
				}
				display: grid;
				grid-template-columns: 1fr;
				justify-content: center;
				text-align: center;
				.info {
					h3 {
						width: 70%;
						margin: auto;
						margin-top: 40px;
						display: grid;
						align-items: center;
						background-color: white;
						border-radius: 10px;
						min-height: 30px;
						padding: 20px;
						font-weight: 400;
						color: grey;
						border: 2px solid #a7a9aa8c;
					}
					.titleDesc {
						text-align: center;
						font-weight: 300;
						font-size: 2.3rem;
						color: #15222e;
						text-shadow: -1px 0 #073a57, 0 1px #073a57,
							1px 0 #073a57, 0 -1px #073a57;
					}
				}
			}
			.titleMedia {
				margin: auto;
				margin-top: 50px;
				text-align: center;
				font-weight: 300;
				font-size: 2.3rem;
				color: #15222e;
				text-shadow: -1px 0 #073a57, 0 1px #073a57, 1px 0 #073a57,
					0 -1px #073a57;
			}
			&__media {
				@media (max-width: 1200px) {
					grid-template-columns: 1fr;
					padding: 0px;
				}
				display: grid;
				grid-template-columns: 1fr;
				width: 90%;
				justify-content: center;
				align-items: center;
				margin: auto;
				padding: 20px;
				&__image {
					margin: auto;
					margin-top: 20px;
					overflow: hidden;
					border-radius: 5px;
					min-height: 320px;
					.titleImage {
						font-weight: 200;
						text-align: center;
						font-size: 1.5rem;
						color: #15222e;
						text-shadow: -1px 0 #073a57, 0 1px #073a57,
							1px 0 #073a57, 0 -1px #073a57;
					}
					a {
						img {
							@media (max-width: 1200px) {
								width: 80%;
							}
							width: 300px;
							height: 300px;
							transition: all 0.2s;
							border: 2px solid #a7a9aa8c;
							border-radius: 8px;
						}
					}
				}
			}
			.dataNpcCompose {
				@media (max-width: 1200px) {
					width: auto;
				}
				margin: auto;
				width: 80%;
				&__titleAndAdd {
					&__title {
						margin-top: 30px;
						text-align: center;
						font-weight: 400;
						font-size: 25px;
						text-transform: uppercase;
						color: #15222e;
						text-shadow: -1px 0 #073a57, 0 1px #073a57,
							1px 0 #073a57, 0 -1px #073a57;
					}
				}
				&__content {
					display: grid;
					grid-template-columns: 1fr;
					border: 2px solid #cac8c8bb;
					margin-top: 20px;
					&__nameAndOptions {
						background-color: #f2f2f2d0;
						display: grid;
						grid-template-columns: 4fr 1fr;
						align-items: center;
						padding: 5px;
						h1 {
							text-align: start;
							font-weight: 300;
							font-size: 20px;
							font-weight: bold;
						}
						&__options {
							text-align: end !important;
						}
					}
					&__data {
						@media (max-width: 1200px) {
							grid-template-columns: 1fr;
						}
						display: grid;
						grid-template-columns: 1fr 3fr;
						align-items: center;
						padding: 5px;
						&__media {
							margin-bottom: 20px;
							overflow-x: scroll;
							h1 {
								text-align: center;
								font-weight: 200;
								font-size: 1.5rem;
								color: #15222e;
							}
							img {
								max-height: 200px;
							}
						}
						&__inf {
							@media (max-width: 1200px) {
								grid-template-columns: 1fr;
							}
							display: grid;
							grid-template-columns: 1fr 1fr 1fr;
							gap: 10px;
							&__info {
								h1 {
									text-align: center;
									font-weight: bold;
									font-size: 1.5rem;
									color: #15222e;
									font-size: 15px;
								}
								p {
									text-align: center;
									font-weight: 500;
									font-size: 1.2rem;
									color: #747474;
								}
								&__sound {
									h1 {
										width: 100%;
										text-align: center;
										font-weight: bold;
										font-size: 1.5rem;
										color: #15222e;
										font-size: 15px;
										margin-bottom: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.challengeAndGameContent {
		&__container {
			.nav-content {
				border: 1px solid #a9abaca9;
				border-bottom: none;
				border-left: none;
				border-right: none;
				.challengeAndGameDataContent {
					display: grid;
					&__btnAdd {
						@media (max-width: 1100px) {
							display: grid;
							grid-template-columns: 1fr !important;
							text-align: center;
							margin-bottom: 20px;
							.boxBtnAdd {
								text-align: center !important;
								.btnAdd {
								}
							}
						}
						width: auto;
						margin: auto;
						display: grid;
						grid-template-columns: 1fr 1fr !important;
						justify-content: center;
						align-items: center;
						h1 {
							color: rgb(14, 30, 53);
							font-weight: bold !important;
							font-size: 23px;
							text-transform: uppercase;
							font-style: italic;
							text-align: start;
						}
						.boxBtnAdd {
							text-align: end;
							.btnAdd {
								margin-top: auto;
								margin-bottom: auto;
							}
						}
					}
				}

				.adminChallengeComponents {
					@media (max-width: 1200px) {
						width: auto;
					}
					font-weight: 400 !important;
					width: auto;
					margin: auto;
					margin-top: 5px;
					background-color: #073a57 1;
					&__button {
						&__title {
							h2 {
								font-size: 1.5rem;
							}
						}
					}
					.challengeContent {
						&__container {
							display: grid;
							grid-template-columns: 1fr;
							text-align: center;
							&__data {
								@media (max-width: 1200px) {
									display: grid;
									grid-template-columns: 1fr !important;
								}
								display: grid;
								grid-template-columns: repeat(4, 1fr);
								justify-content: center;
								text-align: center;
								.info {
									p {
										width: 70%;
										margin: auto;
										display: grid;
										align-items: center;
										background-color: white;
										border-radius: 10px;
										min-height: 30px;
										padding: 20px;
										font-weight: 400;
										color: grey;
										font-size: 20px;
									}
									.titleDesc {
										text-align: center;
										font-weight: 500;
										font-size: 15px;
										font-weight: bold;
										color: #15222e;
									}
								}
							}
						}
					}
				}
				.adminGameComponents {
					@media (max-width: 1200px) {
						width: auto;
					}
					font-weight: 400 !important;
					width: auto;
					margin: auto;
					margin-top: 5px;
					background-color: #073a57 1;
					&__button {
						&__title {
							h2 {
							}
						}
					}
					.gameContent {
						&__container {
							display: grid;
							grid-template-columns: 1fr;
							text-align: center;
							&__data {
								@media (max-width: 1200px) {
									display: grid;
									grid-template-columns: 1fr !important;
								}
								display: grid;
								grid-template-columns: 1fr;
								justify-content: center;
								text-align: center;
								.info {
									.titleDesc {
										margin-top: 30px;
										text-align: center;
										font-weight: 500;
										font-size: 15px;
										font-weight: bold;
										color: #15222e;
									}
									p {
										width: 70%;
										margin: auto;
										display: grid;
										align-items: center;
										background-color: white;
										min-height: 30px;
										font-weight: 400;
										color: grey;
										font-size: 20px;
									}
									audio {
										@media (max-width: 1200px) {
											width: 100px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 860px) {
		&__title {
			h1 {
				font-size: 15px;
			}
		}
	}
}
