$radius1: 20px;

$color1: #05a1d4;
$color2: #000;
$color3: #ccc;
$color4: #fff;
$color5: #296098;
$color6: #26aeca;
$color7: #f77d08;
$color8: #1755ad;
$color9: #f7f7f5;
$color10: #e7b68d;
$color11: #959595;
$color12: #ececec;
$color13: #e24ba8;
$color14: #ef97de;
$color15: #e4e8fe;
$color16: #d8083f;

$colorBrownApp: #490d08;
$colorBlueApp: #00a5da;

$colorLocked: #d2d2d2;
$colorAvailable: $color1;
$colorCompleted: $color8;

.bg7 {
	background-color: $color7;
}

$color1x0: rgba(
	$color: $color1,
	$alpha: 0.1,
); // active background
$color1x1: rgba(
	$color: $color1,
	$alpha: 0.6,
); // active border
$color1x5: rgba(
	$color: $color1,
	$alpha: 0.9,
); // hover

$color2x0: rgba(
	$color: $color2,
	$alpha: 0.05,
); // active background
$color2x1: rgba(
	$color: $color2,
	$alpha: 0.1,
); // border
$color2x2: rgba(
	$color: $color2,
	$alpha: 0.3,
); // active border

$color2t4: rgba(
	$color: $color2,
	$alpha: 0.5,
); // placeholders
$color2t5: rgba(
	$color: $color2,
	$alpha: 0.6,
); // description
$color2t6: rgba(
	$color: $color2,
	$alpha: 0.9,
); // normal
$color2t7: rgba(
	$color: $color2,
	$alpha: 0.8,
); // little description differentiation

$color3x0: rgba(
	$color: $color3,
	$alpha: 0.1,
); // background for section diferentiation

.btnGreen {
	background-color: #36af77;
	border: 2px solid #297a55;
	border-radius: 3px;
	color: #f2f2f2;
	font-weight: 600;
	font-size: 1rem;
	transition: all 200ms;
	&:hover {
		background-color: #32ac73d3;
	}
}
.inputChange {
	background-color: transparent;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid #f2f2f2;
	color: white;
	padding: 5px;
	outline: none;
	&::placeholder {
		color: white;
	}
}
.inputChangeBlack {
	width: 100%;
	background-color: transparent;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid #4d4d4d;
	color: #4d4d4d;
	padding: 5px;
	outline: none;
	&::placeholder {
		color: #4d4d4d;
	}
}
.btnBlue {
	background-color: #0c4d83;
	border: 2px solid #103a69;
	border-radius: 3px;
	color: #f2f2f2;
	font-weight: 600;
	font-size: 1rem;
	transition: all 200ms;
	&:hover {
		background-color: #0c4e83be;
	}
}

.btnRed {
	background-color: #c03838;
	border: 2px solid #b12525;
	border-radius: 3px;
	color: #f2f2f2;
	font-weight: 400;
	font-size: 1rem;
	transition: all 200ms;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: #eb4b4b;
	}
}

.btnGray {
	background-color: #606f83;
	border: 2px solid #0000008a;
	border-radius: 4px;

	color: #f2f2f2;
	font-size: 1.5rem;
	font-weight: 400;

	cursor: pointer;
	transition: all 200ms;
	&:hover {
		background-color: #506a8b;
	}
}

.btnGrayOutline {
	background-color: transparent;
	border: 2px solid #0000008a;
	border-radius: 4px;

	color: #0000008a;
	font-size: 1.3rem;
	font-weight: 400;
	text-transform: capitalize;

	cursor: pointer;
	transition: all 200ms;
	&:hover {
		background-color: #eeeeee;
	}
}

$color5t5: rgba(
	$color: #0a283c,
	$alpha: 0.7,
); // description

$color7x1: rgba(
	$color: $color7,
	$alpha: 0.6,
);

$linear-gradient1: linear-gradient(
	90deg,
	#02442c 0%,
	#17aa78 40%,
	#26aeca 130%
);
$linear-gradient2: linear-gradient(
	90deg,
	#0a283c 0%,
	#296098 40%,
	#3562bb 130%
);
$linear-gradient3: linear-gradient(to top, #0cbd68, #49d49c);

$space1: 1rem;
$space2: 6rem;
$space3: 8rem;
$space4: 2rem;

$border-radius1: 15px;

$shadow1: 0 6px 0 6px $color12;
$shadow2: 0 10px 50px 0px grey;
$shadow3: 0 10px 60px 0px $color2;
$shadow4: 0px 0px 20px 0px $color12;
$shadow5: 0px 0px 5px 3px $color7;

$lesson-bar-height: 32px;
$lesson-slider-transition: left 400ms ease-out;
