@import 'variables';
@import './colors';

.start {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	&__page {
		position: relative;
		flex: 1;
		width: 100vw;
		height: 100%;
		background-image: url('../assets/images/backgroundStart.png');
		background-image: image-set(
			url('../assets/images/backgroundStart.avif') type('image/avif'),
			url('../assets/images/backgroundStart.png') type('image/png')
		);
		background-size: cover;
		background-position: center;
	}
	&__teodoro {
		position: absolute;
		top: 10%;
		left: 0;
		width: 30%;
		& > img {
			width: 100%;
			height: auto;
		}
	}
	&__subscriptions {
		position: relative;
		display: flex;
		gap: 0.7rem;
		left: 8%;
		// top: -25%;
		bottom: -75%;
		max-width: 40rem;
		height: 120px;
		&__container {
			cursor: pointer;
			position: relative;
			width: max-content;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			&__flag1 {
				height: 6rem;
				width: 6rem;
				aspect-ratio: 1/1;
			}
			&__flag2 {
				height: 4rem;
				width: 4rem;
				position: absolute;
				right: -5px;
				top: 2px;
				aspect-ratio: 1/1;
			}
		}
		&__button {
			background: transparent;
			border: none;
			transition: transform 0.3s ease-in-out;
			transform: scale(0.5);
		}
		&__selected {
			transform: scale(1);
		}
	}
	&__teoloro__letters {
		position: absolute;
		top: 10%;
		right: 5%;
		width: 40%;
		& > img {
			width: 100%;
			height: auto;
		}
	}
	&__main {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: end;
		align-items: end;
		width: 100%;
		height: 100%;
		&__start__button {
			display: flex;
			border: none;
			background-color: transparent;
			margin-right: 15rem;
			margin-bottom: 1.5rem;
			align-self: baseline;
			&:hover {
				outline: none;
				filter: drop-shadow(0px 0px 4px black);
			}
			&:active {
				transform: scale(0.95);
				filter: drop-shadow(0px 0px 2px black);
			}
		}
	}
	&__right {
		position: absolute;
		top: 0;
		right: 0;
		width: 50vw;
		min-width: 700px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		&__start__button {
			border: none;
			background-color: transparent;

			&__hidden {
				visibility: hidden;
			}

			&:hover {
				outline: none;
				filter: drop-shadow(0px 0px 4px black);
			}
			&:active {
				transform: scale(0.95);
				filter: drop-shadow(0px 0px 2px black);
			}
		}
	}
}

.containerTutorial {
	position: absolute;
	inset: 0;
	z-index: 1100;
	background: black;

	&Video {
		width: 100%;
		height: 100%;
		display: block;
		aspect-ratio: 16/9;
	}

	&SkipTutorial {
		opacity: 0;
		border: 1px solid transparent;
		background: linear-gradient(#ff7805 50%, #ffa013 50%);
		font-size: 5em;
		font-weight: bold;
		color: #fff;
		position: absolute;
		bottom: 5%;
		right: 5%;
		padding: 0 0.4em;
		border-radius: 35px;
		cursor: pointer;
		clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
		filter: contrast(0.9);
		animation: fade-in-skip-button 2s linear 1s both;

		&:hover {
			border-color: rgba(0, 0, 0, 0.3);
			filter: brightness(1.1);
			transform: scale(1.1);
		}
	}
}

@keyframes fade-in-skip-button {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
