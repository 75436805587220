@use './colors';

$anagram-shadow: 0 5px 30px 0 black;

.image__on__flex1 {
	display: flex;
	gap: 1em;
	flex: 1;
	width: 100%;
	height: 100px;
	align-self: stretch;

	&__container {
		position: relative; // BECAUSE OF THE AUDIO SUPPORT BUTTON
		width: max-content;
		margin: 0 auto;
		// width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		& > img {
			border-radius: 10px;
			border: 2px solid black;
			width: 100%;
			height: auto;
		}
		& > video {
			border-radius: 10px;
			border: 2px solid black;
			width: 100%;
			height: auto;
		}

		& > div {
			position: absolute;
		}
	}

	&__panel_of_words {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		background: #fbce95;
		padding: 1em;
		overflow: auto;
		max-width: 300px;

		& .audio__help__button {
			position: relative;
		}

		& > article {
			display: flex;
			align-items: center;
			gap: 0.5em;

			& > div:nth-child(2) {
				flex-grow: 1;
				text-align: center;

				& > p:first-child {
					color: #613329;
					font-weight: 700;
				}

				& > p:last-child {
					font-weight: 700;
				}
			}
		}

		&__floating {
			position: absolute;
			left: -30%;
			transition: left 0.3s linear;
			z-index: 1;

			&__show {
				left: 0%;
			}
		}

		&__button {
			background: transparent;
			border: none;
			outline: none;
			width: 40px;
			height: 40px;
			cursor: pointer;
			margin-left: auto;

			& > img {
				width: 100%;
				height: 100%;
				display: block;
				aspect-ratio: 1;
			}
		}
	}
}
