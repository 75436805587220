.containerListGameLanguages {
	display: flex;
	flex-direction: column;

	&List {
		margin: 40px 0px 20px 0px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
		&Item {
			min-width: 180px;
			min-height: 180px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 30px 10px 15px 10px;
			border: 2px solid #3498db;
			border-radius: 5px;
			position: relative;

			&Edit {
				position: absolute;
				top: 10px;
				right: 10px;
				background-color: transparent;
				border: none;
				color: rgba(228, 211, 57, 0.7725490196);
				font-size: 18px;
				cursor: pointer;
				z-index: 10;

				&:hover {
					color: #ffe600;
				}
			}

			&Delete {
				position: absolute;
				top: 10px;
				right: 35px;
				background-color: transparent;
				border: none;
				color: rgba(255, 0, 0, 0.7176470588);
				font-size: 18px;
				cursor: pointer;
				z-index: 10;

				&:hover {
					color: #ff0000;
				}
			}

			p {
				margin: 10px 0px 0px 0px;
				font-size: 12px;
			}

			&:hover {
				cursor: pointer;
			}

			form {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 100%;
			}
			&FormGroup {
				display: flex;
				flex-direction: column;
				label {
					font-weight: bold;
					margin: 5px 0px 2px 0px;
				}
			}

			// select {
			// 	appearance: none;
			// 	border-radius: 5px;
			// 	background-color: #fff;
			// 	color: #333;
			// 	padding: 10px;
			// 	font-size: 16px;
			// 	font-family: 'Arial', sans-serif;
			// 	outline: none;
			// 	cursor: pointer;
			// }

			// select:focus {
			// 	border-color: #0066cc; /* Borde azul al enfocarse */
			// }

			// select option {
			// 	padding: 10px; /* Espacio interno en las opciones */
			// }
		}
		&WarningPermissions {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			svg {
				font-size: 70px;
				color: red;
				padding: 10px;
			}
		}
	}
}

.selectedGameLanguage {
	border: 3px solid #3498db;
	p {
		font-weight: bold;
	}
}

@media (max-width: 768px) {
	.containerListGameLanguages {
		&List {
			grid-template-columns: 1fr;
		}
	}
}

@media (min-width: 768px) and (max-width: 1270px) {
	.containerListGameLanguages {
		&List {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
