@import './colors';
$playground-button-size: 32px;

.level__results {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-right: 2rem;
    background: linear-gradient(to right, transparent 15%, #0F1340, #0B0D2E);

    opacity: 1;
    transition-property: opacity;
    transition-duration: 500ms;
    transition-timing-function: ease-out;

    &__animations {
        &__close {
            opacity: 0;
        }
    }

    &__card {
        display: flex;
        justify-content: end;
        flex-direction: column;
        align-items: center;

        width: 40%;
        max-width: 600px;
        min-width: max-content;
        padding: 1rem;

        box-shadow: 10px 10px 50px 0 rgba($color: #000000, $alpha: .7);
        border-radius: 15px;

        background-color: white;

        & > h1 {
            text-align: center;
            padding: 0;
            margin: 0;
            margin-bottom: auto;
            font-size: 3rem;
            font-weight: 900;
            color: rgba($color: #000000, $alpha: .8);

            background: linear-gradient(90deg, #0072ff 0%, #00BBE0 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }
        & > h2 {
            color: #0F1340;
        }
        &__image {
            flex: 1;
            aspect-ratio: 16 / 9;
            margin: 1rem 0;
            max-width: 100%;

            display: flex;
            justify-content: center;

            & > img {
                max-width: 100%;
                height: 100%;
            }
        }
        &__stars {
            display: flex;
            align-items: center;
            gap: 1rem;
            &__image {
                width: 70px;
                height: 70px;

                & > img {
                    width: 100%;
                    height: 100%;
                }
            }
            &__percentage {
                font-size: 4rem;
                color: #0F1340;
            }
        }
        & > p {
            opacity: .7;
        }
        &__buttons {
            width: 100%;
            display: flex;
            gap: 1rem;
            border-top: 2px solid #ddd;
            padding: 1rem;
            padding-bottom: 0;
            margin-top: 1rem;
            &__try__again {
                flex: 1;
                text-decoration: underline;
                text-transform: capitalize;
                font-size: 1.1rem;
                height: $playground-button-size;
                margin-left: auto;
                border-radius: 10px;
                border: none;
                
                background-color: transparent;
                color: orange;
                color: #0F1340;

                &:enabled {
                        cursor: pointer;
                }
            }
            &__complete {
                flex: 1;
                font-weight: bold;
                font-size: 1.1rem;
                text-transform: uppercase;
                height: $playground-button-size;
                
                border-radius: 10px;
                border: 1px solid black;
                background-color: rgba($color: #6FE384, $alpha: .9);
                background: linear-gradient(90deg, #0072ff 0%, #00d4ff 100%);
                color: white;
                &:enabled {
                        cursor: pointer;
                }
                &:active {
                        background-color: rgba($color: #6FE384, $alpha: 1);
                }
                &:disabled {
                        border-color: #888;
                        background-color: rgba($color: #6FE384, $alpha: .5);
                }
            }
        }
    }
}