@use './colors';

.lives {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	height: min-content;
	padding-bottom: 0.6rem;

	&Container {
		position: relative;
		display: flex;
		gap: 0.5rem;
		padding: 0.5rem 0.5rem;

		width: min-content;

		border: 0.5rem solid colors.$color16;
		border-radius: 10px;

		background-color: colors.$color22;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;

			margin: 2% 5%;

			display: block;

			width: 90%;
			height: 0.3rem;
			border-radius: 0.5rem;
			background-color: colors.$color17;
		}

		&Live {
			z-index: 2;
			width: 2.5rem;
			height: 2.5rem;
			margin-bottom: -2rem;

			& > img {
				max-width: 100%;
				height: auto;
			}

			&Animate {
				&Pop {
					position: absolute;
					top: 0;
					left: 0;
					transform-origin: center top;

					@keyframes popAnimation {
						from {
							opacity: 1;
							transform: scale(1);
						}
						to {
							opacity: 0;
							transform: scale(1.4);
						}
					}
					animation: popAnimation 350ms ease-out forwards;
				}
			}
		}
	}
}
