@use './colors';

$border-radius: 10px;

.playground__memory {
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	&__cards__section {
		box-sizing: border-box;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(3, minmax(0, 1fr));

		gap: 0.5rem;
		row-gap: 1.3rem;
		padding-bottom: 1.3rem;

		width: 100%;
		flex: 1;
		height: 0;
		overflow: hidden;
		z-index: 10;

		&__card {
			box-sizing: border-box;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;

			border-radius: $border-radius;
			outline: 1px solid colors.$color19;
			outline-offset: -1px;

			transition: background-color 250ms ease-in-out;

			&__image {
				box-sizing: border-box;
				position: relative;
				width: 100%;
				height: 100%;

				& > img {
					border-radius: $border-radius;
					width: 100%;
					height: 100%;
					object-fit: fill;
				}
				&__text {
					position: absolute;
					bottom: calc(1.5rem / -2);
					left: 0;
					width: 100%;
					min-height: max-content;
					text-align: center;

					display: flex;
					justify-content: center;

					opacity: 1;
					transition: opacity 150ms ease-in-out;
					&__invisible {
						opacity: 0;
					}

					&__center {
						max-width: 130%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 1rem;

						border-radius: 6px;
						border: 2px solid colors.$color19;
						background-color: colors.$color17;
					}
				}
			}
			&__cover {
				box-sizing: border-box;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: white;
				opacity: 1;
				transition: opacity 150ms ease-in-out;

				border-radius: $border-radius;

				background-color: colors.$color19;
				background-image: url(../assets/images/woodTexture.jpg);
				background-image: image-set(
					url(../assets/images/woodTexture.avif) type("image/avif"),
					url(../assets/images/woodTexture.jpg) type("image/jpg")
				);
				background-size: cover;
				&__covered {
					&:hover {
						cursor: pointer;
						filter: saturate(1.2);
					}
				}
				&__covered__disabled {
					filter: grayscale(80%);
				}
				&__uncovered {
					opacity: 0;
				}
			}
		}
	}
}
