@import 'variables';

.objectsAdmin {
	font-family: 'League Spartan', sans-serif;
	font-weight: 400 !important;
	background-color: white;
	padding: 30px;
	min-height: 100vh !important;

	&__containerGen {
		&__title {
			h1 {
				font-weight: bold !important;
				font-size: 25px;
				text-align: end;
				margin-top: 0px;
				margin-bottom: 20px;
				text-transform: uppercase;
			}
			h2 {
				text-align: center;
				margin-bottom: 10px;
			}
		}
		&__btns {
			display: flex;
			border: 2px solid #cac8c8bb;
			flex-direction: row;
			gap: 10px;
			padding: 5px;
		}
		@media (max-width: 860px) {
			&__btns {
				flex-direction: column;
			}
		}
	}

	.contentErrorPerms {
		margin-top: 100px;
		text-align: center;
		.iconErrorPerms {
			color: #c00d0db0;
			font-size: 5rem;
			margin: auto;
		}
		.textErrorPerms {
			color: #c00d0db0;
			margin: auto;
			font-size: 2rem;
		}
	}

	.decorationSceneWorld {
		width: 100%;
		flex-direction: column;

		&__btnAnimations {
			.boxBtnAnimations {
				display: flex;
				align-items: center;
				justify-content: center;
				.btnAdd {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #40b67f;
					color: #fff;
					border: 2px solid #40b67f;
					text-align: center !important;
					margin-top: 5px;
					margin-left: 20px;
					width: 150px;
					transition: all 0.2s;
					height: 50px;
					font-size: 16px;
					font-weight: bold;
					&:hover {
						cursor: pointer;
						background-color: #fff;
						color: #40b67f;
					}
					.pIcons {
						margin: 0 10px 0 0;
					}
				}
			}
		}

		&__btnAdd {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 2fr 1fr;
			align-items: center;
			h1 {
				font-weight: bold !important;
				font-size: 25px;
				text-transform: uppercase;
				text-align: start;
			}
			.boxBtnAdd {
				text-align: end;
			}
		}

		&__content {
			&__listDecorations {
				margin-top: 15px;
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}

	.doorSceneWorld {
		width: 100%;
		flex-direction: column;
		&__btnAdd {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 2fr 1fr;
			align-items: center;
			h1 {
				font-weight: bold !important;
				font-size: 25px;
				text-transform: uppercase;
				text-align: start;
			}
			.boxBtnAdd {
				text-align: end;
			}
		}

		&__content {
			&__listDoors {
				margin-top: 15px;
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}

	.storyTellerSceneWorld {
		margin-top: 10px;
		width: 100%;
		flex-direction: column;
		&__btnNpcs {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
		}
		&__btnAdd {
			margin-top: 30px;
			display: grid;
			grid-template-columns: 2fr 1fr;
			align-items: center;
			h1 {
				font-weight: bold !important;
				font-size: 25px;
				text-transform: uppercase;
				text-align: start;
			}
			.boxBtnAdd {
				text-align: end;
			}
		}

		&__content {
			&__listStories {
				margin-top: 15px;
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}

		.storyTellerContent {
			&__container {
				width: 100%;
				&__data {
					.info {
						h1 {
							font-weight: bold !important;
							font-size: 20px;
							text-transform: uppercase;
						}
						p {
							margin-top: 10px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}

		.storyTellerContentData {
			&__btnAdd {
				@media (max-width: 1200px) {
					display: grid;
					grid-template-columns: 1fr !important;
					h1 {
						text-align: center;
					}
					.boxBtnAdd {
						text-align: center !important;
					}
				}
				margin-top: 10px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				align-items: center;

				h1 {
					font-weight: bold !important;
					font-size: 20px;
					text-transform: uppercase;
				}
				.boxBtnAdd {
					text-align: end;
				}
			}

			&__Content {
				margin-top: 10px;
				display: flex;
				gap: 5px;
				flex-direction: column;
			}
		}

		.storyTellerContentTwoBody {
			&__container {
				display: flex;
				flex-wrap: wrap;
				&__Left {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
				}
				&__Right {
					width: 50%;
				}
				.info {
					h1 {
						font-weight: bold !important;
						font-size: 20px;
						text-align: center;
					}
					p {
						text-align: center;
						border-radius: 10px;
						font-size: 1.3rem;
						margin-bottom: 20px;
					}
					audio {
						margin-top: 10px;
						margin-bottom: 20px;
					}
				}
				@media (max-width: 860px) {
					&__Left {
						width: 100%;
					}
					&__Right {
						width: 100%;
					}
				}
			}
		}
	}

	.npcSceneContent {
		&__container {
			display: flex;
			flex-wrap: wrap;

			&__Left {
				width: 30%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				&__Form {
					display: flex;
					flex-direction: column;
					label {
						margin-top: 5px;
						margin-bottom: 5px;
						width: 100%;
						text-align: center;
						font-weight: bold;
					}
					&__Columns {
						display: flex;
						gap: 10px;
						&__Input {
							width: 110px;
						}
					}
					&__Save {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 10px;
						margin-bottom: 10px;
					}
				}
				img {
					max-height: 250px;
				}
			}
			&__Right {
				padding: 5px;
				width: 70%;
				display: flex;
				gap: 5px;
				flex-direction: column;
			}
			&__Center {
				width: 100%;
			}

			&__media {
				overflow-x: scroll;
			}

			&__components {
				width: 100%;
				&__btnAdd {
					@media (max-width: 1200px) {
						display: grid;
						grid-template-columns: 1fr !important;
						h1 {
							text-align: center;
						}
						.boxBtnAdd {
							text-align: center !important;
						}
					}
					margin-top: 20px;
					display: grid;
					grid-template-columns: 1fr 1fr;
					align-items: center;
					h1 {
						text-align: start;
						color: rgb(14, 30, 53);
						font-weight: bold !important;
						font-size: 20px;
						text-transform: uppercase;
					}
					.boxBtnAdd {
						text-align: end;
					}
				}
				&__content {
					margin-top: 15px;
					display: flex;
					flex-direction: column;
					gap: 5px;
				}
			}

			@media (max-width: 860px) {
				&__Left {
					width: 100%;
				}
				&__Right {
					width: 100%;
				}
			}
		}
	}

	.dialoguesContent {
		display: flex;
		flex-wrap: wrap;
		&__Left {
			width: 50%;
		}
		&__Right {
			width: 50%;
		}

		.info {
			h1 {
				font-size: 15px;
				margin-top: 20px;
			}
			p {
				font-size: 20px;
			}
			audio {
				margin-top: 10px;
			}
		}
	}

	.missionContentData {
		&__container {
			display: flex;
			flex-wrap: wrap;

			&__Left {
				width: 40%;
			}
			&__Right {
				width: 60%;
			}

			.info {
				h1 {
					font-size: 18px;
				}
				p {
					font-size: 20px;
					margin-bottom: 15px;
				}
				audio {
					margin-top: 10px;
					margin-bottom: 10px;
				}
				img {
					margin-top: 10px;
					margin-bottom: 10px;
					max-height: 130px;
				}
			}
		}
		&__titleSection {
			font-size: 25px;
			text-align: center;
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}
}

.doorContentBody {
	width: 100%;
	&__container {
		hr {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		&__infoColumn {
			display: flex;
			flex-wrap: wrap;
			h1 {
				text-transform: uppercase;
				width: 100%;
				font-size: 20px;
			}
			&__Left {
				width: 70%;
				margin-top: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow-x: scroll;
				flex-direction: column;
				img {
					max-height: 170px;
				}
				p {
					width: 100%;
					text-align: center;
				}
			}
			&__Right {
				width: 30%;
				margin-top: 5px;
				&__Info {
					margin-top: 5px;
					margin-left: 10px;
				}
				&__Buttons {
					display: flex;
					gap: 5px;
				}
			}

			@media (max-width: 860px) {
				&__Left {
					width: 100%;
				}
				&__Right {
					width: 100%;
					&__Buttons {
						flex-direction: column;
					}
				}
			}
		}
	}
}

.animations {
	font-family: 'League Spartan', sans-serif;
	font-weight: 400 !important;
	background-color: white;
	min-height: 100vh;
	&__container {
		padding-top: 30px;
		margin: auto;
		width: 90%;
		&__title {
			text-align: center;
			h1 {
				margin: auto;
			}
		}
		&__btnAnimations {
			.boxBtnAnimations {
				display: grid;
				justify-content: center;
			}
		}
		&__btnAdd {
			display: grid;
			grid-template-columns: 1fr;
			align-items: center;
			.boxBtnAdd {
				text-align: center;
			}
		}
		.contentErrorPerms {
			margin-top: 100px;
			text-align: center;
			.iconErrorPerms {
				color: #c00d0db0;
				font-size: 5rem;
				margin: auto;
			}
			.textErrorPerms {
				color: #c00d0db0;
				margin: auto;
				font-size: 2rem;
			}
		}
		.adminComponents {
			width: 98%;
			margin: auto;
			margin-top: 20px;
			margin-bottom: 40px;
		}
	}
}

.mission {
	@media (max-width: 1200px) {
		width: auto;
	}
	margin: auto;
	width: 100%;
	&__btnAdd {
		h1 {
			text-align: start;
			font-weight: bold !important;
			font-size: 22px;
			margin-bottom: 10px;
			text-transform: uppercase;
		}
		&__boxBtnAdd {
			text-align: end;
			display: flex;
			justify-content: center;
			gap: 10px;
			flex-direction: row;
		}
	}
	&__content {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.titleMission {
		border: 2px solid #a7a9aa8c;
		border-radius: 10px 10px 0px 0px;
		padding: 10px;
		display: grid;
		grid-template-columns: 3fr 1fr;
		align-items: center;
		margin-top: 30px;
		.title {
			display: grid;
			font-weight: 400 !important;
			align-items: center;
			h2 {
				font-size: 1.9rem;
				text-align: start;
				color: black;
				font-weight: 500 !important;
			}
		}
		.options {
			text-align: end !important;
			.btnDelete {
				font-size: 1.5rem !important;
				p {
					margin: 0px;
					color: #c00d0db0;
				}
				&:hover {
					transform: translateY(-5px);
					background-color: transparent;
				}
			}
			.btnEdit {
				font-size: 1.5rem !important;
				p {
					margin: 0px;
					color: #e4d339;
				}
				&:hover {
					transform: translateY(-5px);
					background-color: transparent;
				}
			}
		}
	}
	.contentMission {
		&__data {
			@media (max-width: 1200px) {
				grid-template-columns: 1fr;
			}
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 10px;
			.info {
				h1 {
					color: rgb(14, 30, 53);
					font-weight: 400 !important;
					font-size: 1.7rem;
					text-align: center;
				}
				p {
					background-color: #ebebebbb;
					border: 2px solid #cac8c8bb;
					border-radius: 10px;
					display: grid;
					grid-template-columns: 1fr;
					margin: auto;
					margin-top: 20px;
					border-radius: 10px;
					padding: 15px;
					text-align: center;
					font-size: 1.3rem;
				}
			}
		}

		.missionContent {
			@media (max-width: 1200px) {
				width: auto;
			}
			margin: auto;
			width: 90%;
			&__btnAdd {
				@media (max-width: 1200px) {
					display: grid;
					grid-template-columns: 1fr !important;
					h1 {
						text-align: center;
					}
					.boxBtnAdd {
						text-align: center !important;
					}
				}
				display: grid;
				grid-template-columns: 1fr 1fr;
				align-items: center;
				h1 {
					text-align: start;
					color: rgb(14, 30, 53);
					font-weight: 400 !important;
					font-size: 2.3rem;
				}
				.boxBtnAdd {
					text-align: end;
				}
			}
			.titleMissionContent {
				margin-top: 20px;
				background-color: #f2f2f2;
				border: 2px solid #a7a9aa8c;
				border-radius: 10px 10px 0px 0px;
				padding: 10px;
				display: grid;
				cursor: pointer;
				grid-template-columns: 3fr 1fr;
				align-items: center;
				.title {
					display: grid;
					font-weight: 400 !important;
					align-items: center;
					h2 {
						font-size: 1.9rem;
						text-align: start;
						color: black;
						font-weight: 500 !important;
					}
				}
				.options {
					text-align: end !important;
					.btnDelete {
						font-size: 1.5rem !important;
						p {
							margin: 0px;
							color: #c00d0db0;
						}
						&:hover {
							transform: translateY(-5px);
							background-color: transparent;
						}
					}
					.btnEdit {
						font-size: 1.5rem !important;
						p {
							margin: 0px;
							color: #e4d339;
						}
						&:hover {
							transform: translateY(-5px);
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	&__BodyCollapse {
		&__Info {
			h1 {
				font-size: 20px;
			}
			p {
				font-size: 18px;
				margin-bottom: 10px;
			}
		}
	}
}

.missionContentBodyCollapse {
	&__btnAdd {
		@media (max-width: 860px) {
			display: grid;
			grid-template-columns: 1fr;
			text-align: center;
			.boxBtnAdd {
				text-align: center !important;
			}
		}
		width: 100%;
		margin: auto;
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		align-items: center;
		margin-top: 50px;
		h1 {
			font-weight: bold !important;
			font-size: 25px;
			text-transform: uppercase;
		}
		.boxBtnAdd {
			text-align: end;
			.btnAdd {
				margin-top: auto;
				margin-bottom: auto;
			}
		}
	}
	&__Content {
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
}

.npcBank {
	font-family: 'League Spartan', sans-serif;
	font-weight: 400 !important;
	background-color: white;
	min-height: 100vh;
	&__container {
		padding-top: 20px;
		margin: auto;
		width: 95%;
		h1 {
			color: rgb(14, 30, 53);
			font-weight: 400 !important;
			text-shadow: 1px 1px 1px rgb(14, 30, 53);
			font-size: 2.3rem;
			text-align: center;
		}

		.adminComponents {
			@media (max-width: 1200px) {
				width: auto;
			}
			font-weight: 400 !important;
			width: auto;
			margin: auto;
			margin-top: 20px;
			margin-bottom: 10px;
			.npcContent {
				&__container {
					display: grid;
					grid-template-columns: 1fr;
					text-align: center;
					&__data {
						@media (max-width: 1200px) {
							display: grid;
							grid-template-columns: 1fr !important;
						}
						display: grid;
						grid-template-columns: 1fr;
						justify-content: center;
						text-align: center;
						.info {
							h3 {
								width: 70%;
								margin: auto;
								margin-top: 40px;
								display: grid;
								align-items: center;
								background-color: white;
								border-radius: 10px;
								min-height: 30px;
								padding: 20px;
								font-weight: 400;
								color: grey;
								border: 2px solid #a7a9aa8c;
							}
							.titleDesc {
								text-align: center;
								font-weight: 300;
								font-size: 2.3rem;
								color: #15222e;
								text-shadow: -1px 0 #073a57, 0 1px #073a57,
									1px 0 #073a57, 0 -1px #073a57;
							}
						}
					}
					.titleMedia {
						margin: auto;
						margin-top: 50px;
						text-align: center;
						font-weight: 300;
						font-size: 2.3rem;
						color: #15222e;
						text-shadow: -1px 0 #073a57, 0 1px #073a57,
							1px 0 #073a57, 0 -1px #073a57;
					}
					&__media {
						@media (max-width: 1200px) {
							grid-template-columns: 1fr;
							padding: 0px;
						}
						display: grid;
						grid-template-columns: 1fr;
						width: 90%;
						justify-content: center;
						align-items: center;
						margin: auto;
						padding: 20px;
						&__image {
							margin: auto;
							margin-top: 20px;
							overflow: hidden;
							border-radius: 5px;
							min-height: 320px;
							.titleImage {
								font-weight: 200;
								text-align: center;
								font-size: 1.5rem;
								color: #15222e;
								text-shadow: -1px 0 #073a57, 0 1px #073a57,
									1px 0 #073a57, 0 -1px #073a57;
							}
							a {
								img {
									@media (max-width: 1200px) {
										width: 80%;
									}
									width: 300px;
									height: 300px;
									transition: all 0.2s;
									border: 2px solid #a7a9aa8c;
									border-radius: 8px;
								}
							}
						}
					}
					.dataNpcCompose {
						@media (max-width: 1200px) {
							width: auto;
						}
						margin: auto;
						width: auto;
						max-height: min-content;
						&__titleAndAdd {
							&__title {
								margin-top: 60px;
								text-align: center;
								font-weight: 300;
								font-size: 2.3rem;
								color: #15222e;
								text-shadow: -1px 0 #073a57, 0 1px #073a57,
									1px 0 #073a57, 0 -1px #073a57;
							}
						}
						&__content {
							display: grid;
							grid-template-columns: 1fr;
							border: 2px solid #cac8c8bb;
							border-radius: 10px;
							margin-top: 20px;
							&__nameAndOptions {
								border-radius: 10px 10px 0px 0px;
								background-color: #f2f2f2d0;
								display: grid;
								grid-template-columns: 4fr 1fr;
								align-items: center;
								padding: 5px;
								border-bottom: 2px solid #cac8c8bb;
								h1 {
									text-align: start;
									font-weight: 300;
									font-size: 1.9rem;
									color: #15222e;
									text-shadow: -1px 0 #073a57, 0 1px #073a57,
										1px 0 #073a57, 0 -1px #073a57;
								}
								&__options {
									margin-top: 10px;
									text-align: end !important;
									.btnDelete {
										font-size: 1.5rem !important;
										p {
											margin: 0px;
											color: #c00d0db0;
										}
										&:hover {
											transform: translateY(-5px);
											background-color: transparent;
										}
									}
									.btnEdit {
										font-size: 1.5rem !important;
										p {
											margin: 0px;
											color: #e4d339;
										}
										&:hover {
											transform: translateY(-5px);
											background-color: transparent;
										}
									}
								}
							}
							&__data {
								@media (max-width: 1200px) {
									grid-template-columns: 1fr;
								}
								display: grid;
								grid-template-columns: 1fr 3fr;
								align-items: center;
								padding: 5px;
								&__media {
									margin: auto;
									width: 260px;
									height: 260px;
									overflow-x: scroll;
									@media (max-width: 1200px) {
										img {
											border-radius: 10px;
										}
									}
									margin-bottom: 20px;
									h1 {
										text-align: center;
										font-weight: 200;
										font-size: 1.5rem;
										color: #15222e;
										text-shadow: -1px 0 #073a57,
											0 1px #073a57, 1px 0 #073a57,
											0 -1px #073a57;
									}
									img {
										border-radius: 10px;
									}
								}
								&__inf {
									@media (max-width: 1200px) {
										grid-template-columns: 1fr;
									}
									display: grid;
									grid-template-columns: 1fr 1fr 1fr;
									gap: 10px;
									&__info {
										h1 {
											text-align: center;
											font-weight: 200;
											font-size: 1.5rem;
											color: #15222e;
											text-shadow: -1px 0 #073a57,
												0 1px #073a57, 1px 0 #073a57,
												0 -1px #073a57;
										}
										p {
											text-align: center;
											font-weight: 500;
											font-size: 1.2rem;
											color: #747474;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.challengeGame {
	font-family: 'League Spartan', sans-serif;
	background-color: white;
	min-height: 100vh;
	padding: 10px;
	&__container {
		padding-top: 20px;
		width: 85%;
		margin: auto;
		.contentErrorPerms {
			margin-top: 100px;
			text-align: center;
			.iconErrorPerms {
				color: #c00d0db0;
				font-size: 5rem;
				margin: auto;
			}
			.textErrorPerms {
				color: #c00d0db0;
				margin: auto;
				font-size: 2rem;
			}
		}
	}
}
