@use './colors';
@use './utils';

$border: 2px solid
	rgba(
		$color: black,
		$alpha: 0.3,
	);
$space: 1.5rem;
$space2: 0.8rem;

.inventory {
	width: 90vw;
	min-width: 150px;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	&__section {
		flex: 1;
		border-radius: 6px;
		border: 1px solid rgba($color: black, colors.$alpha: 0.1);
		background-color: white;

		&__title {
			font-size: 1rem;
			text-transform: capitalize;
			border: $border;
			width: min-content;
			border-radius: 6px;
			padding: 0.3rem 1rem;
			margin: $space2 0 $space2 $space;
		}
		&__items {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
			grid-template-rows: auto;
			grid-gap: 2rem;

			padding: 1.5rem;

			&__item {
				display: flex;
				flex-direction: column;
				gap: 0.6rem;

				padding: 0.5rem;
				border-radius: 15px;
				border: $border;

				background-color: white;

				&__sold {
					border-color: #ccc;
					opacity: 0.7;
					background-color: #ccc;
					box-shadow: none;
				}
				&__selected {
					background-color: colors.$color20; //#ccc;
					box-shadow: none;
					color: colors.$color4;
				}
				&__image__container {
					border-radius: 6px;
					aspect-ratio: 1 / 1;
					overflow: hidden;

					& > img {
						max-width: 100%;
						height: auto;
					}
				}
				&__button {
					@extend .main__button;

					&__buy {
						background-color: colors.$color19;
						color: white;
					}
					&__select {
						background-color: transparent;
						border: $border;

						&__selected {
							text-transform: capitalize;
							border: none;
							background-color: colors.$color19;
							color: white;
						}
					}

					&:enabled {
						&:hover {
							background-color: colors.$color29;
							color: black;
						}
					}
					&:disabled {
						cursor: default;
						filter: grayscale(1);
					}
				}
			}
		}
	}
}
