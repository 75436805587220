@use './vars';
@use './colors';

.step__sliding__bar {
	position: relative;
	overflow: hidden;
	height: vars.$lesson-bar-height;
	display: flex;

	border-radius: 10px;
	border: 2px solid black;

	// ----- ADDITIONAL CLASSNAMES
	&__lesson {
		height: vars.$lesson-bar-height;
		border-color: black;
	}
	&__stepmissions {
		height: 0.8rem;
		border-color: colors.$color18;
	}
	// ***** ADDITIONAL CLASSNAMES

	&__slider {
		position: absolute;
		top: 0;
		left: -50%;
		width: 100%;
		height: 100%;
		background-color: colors.$color19;

		transition: vars.$lesson-slider-transition;

		// ----- ADDITIONAL CLASSNAMES
		&__lesson {
			background-color: colors.$color19;
		}
		&__stepmissions {
			background-color: colors.$color27;
		}
		// ***** ADDITIONAL CLASSNAMES
	}
	&__divider {
		flex: 1;
		z-index: 10;
		border-right: 2px solid black;

		&:last-of-type {
			border-right: none;
		}
	}
}
