@use './colors';

$menubar-button-width: 70px;
$stepmissions-character-width: 110px;
$stepmissions-vertical-padding: 0.3rem;

.switcher {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	opacity: 1;
}

.bar {
	z-index: 10;
	position: fixed;
	display: flex;
}

.scene {
	position: relative;
	&__basicdata {
		z-index: 10 !important;
		font-size: 0.9rem;
		position: absolute;
		top: 20px;
		right: 20px;

		display: flex;
		gap: 1rem;

		&__container {
			min-width: 8ch;

			display: flex;
			align-items: center;
			gap: 0.5rem;

			border-radius: 10px;
			border: 2px solid colors.$color18;
			background-color: colors.$color21;
			color: colors.$color18;
			padding: 0.3rem 1rem;

			&__animate {
				&__shake {
					@keyframes shakeAnimation {
						0% {
							transform: scale(1);
						}
						50% {
							transform: scale(1.16);
						}
					}
					animation: shakeAnimation 140ms ease-out infinite forwards;
				}
			}
		}
	}
	&__zoombuttons {
		z-index: 10 !important;
		font-size: 0.9rem;
		position: absolute;
		top: 70px;
		right: 20px;

		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		&__button {
			cursor: pointer;
			font-size: 1.2rem;
			font-weight: bold;
			width: 36px;
			aspect-ratio: 1 / 1;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			border: none;
			background-color: colors.$color18;
			color: white;
		}
	}
	&__menubar {
		@extend .bar;
		top: 130px;
		left: calc(
			15px +
				calc(
					($stepmissions-character-width - $menubar-button-width) / 2
				)
		);

		flex-direction: column;
		gap: 1.5rem;

		& > button {
			position: relative;
			border-radius: 50%;
			border: 2px solid colors.$color18;
			background-color: white;

			cursor: pointer;
			& > div {
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				padding: 0.5rem;

				&:first-child {
					background-color: colors.$color26;
					& > picture > img {
						width: 100%;
						height: auto;
						max-width: 64px;
						max-height: 64px;
					}

					& > label {
						position: absolute;
						bottom: -20%;
						left: 40%;
						background-color: #fff;
						padding: 5px;
						border-radius: 5px;
						font-weight: 700;
					}
				}
				&:nth-child(2) {
					// RED DOT
					position: absolute;
					top: -6px;
					right: -6px;

					width: 12px;
					height: 12px;

					border-radius: 50%;
					border: 1px solid black;
					outline: 1px solid white;

					background-color: red;
				}
			}
			&:active {
				outline: 1px solid colors.$color2;
				outline-offset: 1px;
			}
		}
		&__store {
			& > div {
				&:first-child {
					background-color: colors.$color28;
				}
			}
		}
	}
	&__missionbubble {
		@extend .bar;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 500px;
		height: $stepmissions-character-width;
		left: 15px;
		top: 15px;

		&__left {
			cursor: pointer;
			width: 5rem;
			max-height: 5rem;
			aspect-ratio: 1 / 1;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: opacity 300ms ease-out;
			div {
				max-width: 100%;
				max-height: 100%;
			}
		}
		&__right {
			overflow: hidden;
			flex: 1;
			align-self: stretch;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 1rem;
			padding-right: 0.7rem;

			&__bubble {
				position: relative;
				height: 90%;
				padding: 0.2rem 1.5rem;
				border-radius: 10px;
				border: 3px solid colors.$color18;
				background-color: colors.$color21;
				color: colors.$color18;

				transition: opacity 300ms ease-out;
				@keyframes openBubbleAnimation {
					from {
						left: -100%;
					}
					to {
						left: 0%;
					}
				}
				&__animate {
					&__open {
						animation: openBubbleAnimation 300ms ease-out forwards;
					}
				}

				&__corner {
					width: 16px;
					height: 22px;
					position: absolute;
					top: 20px;
					left: -16px;

					& > img {
						max-width: 100%;
						height: auto;
					}
				}
				& > h3 {
					font-size: 1.3rem;
					margin-bottom: 0.2rem;
				}
				& > p {
					font-size: 0.9rem;
					font-weight: bold;
				}
				&__button {
					cursor: pointer;
					position: absolute;
					top: -8px;
					right: -10px;
				}
			}
		}
	}
	&__mute__button {
		cursor: pointer;
		position: fixed;
		bottom: 20px;
		left: 20px;
		width: 40px;
		height: 40px;
		padding: 5px;
		margin: 0.3rem;
		border-radius: 6px;
		border: 1px solid transparent;

		background-color: colors.$color2;

		&:hover {
			outline: 1px solid #095c92; // colors.$color2;
			outline-offset: 3px;
		}
		&:active {
			outline: 1px solid #095c92; //colors.$color2;
			outline-offset: 1px;
		}

		&__image {
			width: 100%;
			height: 100%;
			max-width: 64px;
			max-height: 64px;
		}
	}
	&__cover {
		z-index: 1000;
		position: fixed;
		top: 0;
		left: -500%;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0;

		&__fade {
			left: 0;
			animation: coverFadeAnimation 2000ms linear;
		}
	}
	&__level__entrance__door_button {
		cursor: pointer;
		position: absolute;
		bottom: 15px;
		right: 15px;

		padding: 0.5rem 2rem;
		border-radius: 6px;
		border: none;

		&:active {
			transform: scale(0.95);
		}
	}
}

@keyframes coverFadeAnimation {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.map {
	&__door {
		&__badge {
			width: 316px;
			height: 170px;
		}
		&__locked {
			font-family: 'Nunito Sans', sans-serif;
			// font-size: calc(0.8rem + 1.5vw);
			font-size: min(1.5em, calc(0.4rem + 1.5vw));
			text-align: center;

			display: flex;
			justify-content: center;
			align-items: center;

			width: 350px;
			padding: 1.5rem;

			border-radius: 10px;
			border: 1px solid rgba($color: #000, $alpha: 0.8);

			background-color: rgba($color: #111, $alpha: 0.9);
			color: #fff;
		}
		&__level {
			font-family: 'Philosopher', sans-serif;
			font-size: 8rem;
			text-shadow: #fff -3px -3px 0px, #fff 3px 3px 0px;
		}
		&__bar {
			overflow: hidden;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			width: 250px;
			height: max-content;

			border-radius: 18px;
			border: 2px solid black;
			outline: 2px solid white;
			outline-offset: 2px;

			background-color: white;
			&__slider {
				position: absolute;
				top: 0;
				right: 50%;
				width: 100%;
				height: 100%;
				z-index: -2;
				background-color: #d1d6ed;
			}
			&__text__container {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;
			}
			&__span {
				font-size: 1.6rem;
				padding-top: 0.3rem;
				height: min-content;
			}
			&__span2 {
				font-size: 1.6rem;
				text-align: center;
				vertical-align: center;
				width: 2rem;
				height: 2rem;
			}
			&__star {
				max-width: 100%;
			}
		}
		&__opening__bar {
			box-sizing: border-box;
			overflow: hidden;
			width: 150px;
			height: 25px;
			outline: 2px solid #eee;
			border: 5px solid black;
			background-color: black;

			&__slider {
				position: relative;
				left: -100%;
				width: 100%;
				height: 100%;
				background-color: orange;

				&__animations {
					&__complete {
						animation: completeOpeningBar 1250ms linear forwards;
					}
				}
			}
		}
	}
	&__easter__egg {
		&__letter {
			font-family: 'Nunito Sans', sans-serif;
			font-size: min(1.5em, calc(0.4rem + 1.5vw));
			text-align: center;

			display: flex;
			justify-content: center;
			align-items: center;

			width: 400px;
			height: max-content;
			max-height: 100%;
			overflow: hidden;
			padding: 1.5rem;

			border-radius: 10px;
			border: 1px solid rgba($color: #000, $alpha: 0.8);

			background-color: rgba($color: #fff, $alpha: 0.9);
			color: #111;
		}
	}
	&__npc {
		&__name {
			font-family: 'Philosopher', sans-serif;
			font-size: 1.7rem;
			font-weight: bold;

			width: max-content;

			padding: 1px 24px;
			border-bottom-right-radius: 16px;
			border-bottom-left-radius: 16px;

			border: 3px solid #fde14a;
			background: linear-gradient(#fb8249, #feb749);

			color: white;
		}
		&__greeting {
			font-family: 'Nunito Sans', sans-serif;
			font-size: calc(1rem + 1.5vw);
			text-align: center;

			display: flex;
			justify-content: center;
			align-items: center;

			width: 300px;
			padding: 0 0.7em;

			border-top-right-radius: 15px;
			border-bottom-left-radius: 15px;
			border: 4px solid #494b72;
			outline: 2px solid #6c76af;

			background-color: #282844;
			color: white;
		}
		&__button {
			user-select: none;
			font-size: clamp(1rem, 0.4rem + 1.5vw, 2rem);
			text-align: center;

			padding: 0rem 1rem;

			width: 250px;
			min-width: max-content;
			min-height: min-content;

			border-radius: 0;
			border: 1px solid black;

			background-color: yellow;
			color: black;

			box-shadow: 0px 8px 15px 10px rgba(0, 0, 0, 0.1);
		}
		&__top__text {
			font-family: 'Nunito Sans', sans-serif;
			font-size: calc(1.5rem + 1.5vw);
			text-align: center;

			display: flex;
			justify-content: center;
			align-items: center;

			width: 300px;
			padding: 0 0.7em;

			border-top-right-radius: 15px;
			border-bottom-left-radius: 15px;
			border: 1px solid rgba(255, 255, 255, 0.7);

			background-color: rgba(0, 0, 0, 0.4);
			color: white;
		}
	}
	&__lost__item {
		&__letter {
			font-family: 'Nunito Sans', sans-serif;
			// font-size: calc(1rem + 1.5vw);
			font-size: min(1.5em, calc(0.4rem + 1.5vw));
			text-align: center;
			line-height: 120%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 300px;
			padding: 1.5rem;
			border-radius: 10px;
			border: 3px solid rgba($color: #00a6fa, $alpha: 0.8);
			background-color: rgba($color: #edf9ff, $alpha: 0.9);
			color: #094f76;
		}
	}
	&__rewards {
		z-index: 10000 !important;
		display: flex;
		gap: 1rem;

		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-size: 3rem;
		font-weight: bold;

		border: 2px solid black;

		background-color: rgba($color: yellow, $alpha: 0.3);
	}
	&__coin__image {
		width: 3rem;
		height: 3rem;
		background: url('../assets/icons/coin.svg');
		background-repeat: no-repeat;
	}
}

@keyframes completeOpeningBar {
	from {
		left: -100%;
	}
	to {
		left: 0;
	}
}
