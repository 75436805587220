@import 'variables';

form{
    .searchCamp{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        &__input{
            font-size: 15px;
            height: 44px;
            padding-left: 5px;
        }
        &__btn{
            padding: 10px 20px;
            background-color: #fff;
            color: #3498db;
            border: 2px solid #3498db;
            font-size: 16px;
            font-weight: bold;
            margin-left: 5px;
            height: 44px;
            
            &:hover{
                cursor: pointer;
                background-color: #3498db;
                color: #fff;
            }

        }
    }
}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    &__btn{
        background: #3498db;
        color: black;
        font-weight: bold;
        width: 40px;
        height: 40px;
        border-radius: 5%;
        overflow: hidden;
        border: none;
        transition: all 0.4s;
        &:hover{
            background: #8ec7e9;
            box-shadow:0px 0px 2px #9DA9BD; 
        }
        &:disabled{
            background: #8ec7e9;
            opacity: .4;
        }
    }
    &__number{
        font-family: 'League Spartan', sans-serif;
        display: grid;
        text-align: center;
        align-items: center;
        background: #3498db;
        color: white;
        font-weight: bold;
        width: 50px;
        height: 50px;
        border-radius: 5%;
        border: none;
        margin: 5px;
        transition: all 0.4s;
        
    }

}