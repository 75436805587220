@use './colors';
@use './vars';

$lesson-padding: 1rem;
$lesson-aspect-ratio: 4 / 3;
$lesson-close-size: 56px;

.lesson {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 50rem;
	width: 90%;
	height: 85%;
	border-radius: 36px;
	border: 12px solid colors.$color18;
	background-color: white;
	aspect-ratio: 16/9;
	// ----- $SECTIONS
	&__upper {
		padding: $lesson-padding;
		margin-right: calc($lesson-close-size/2);
	}
	&__main {
		overflow: hidden;
		flex: 1;
		width: 100%;
		max-width: 100%;

		&__slider {
			display: flex;
			width: 100%;
			height: 100%;
			position: relative;
			left: 0%;
			transition: vars.$lesson-slider-transition;
		}
	}
	&__bottom {
		display: flex;
		justify-content: end;
		padding: $lesson-padding;
	}
	// ----- $COMPONENTS
	&__close {
		width: $lesson-close-size;
		height: $lesson-close-size;
		position: absolute;
		top: calc($lesson-close-size / -3);
		right: calc($lesson-close-size / -3);
		border: none;
		background-color: transparent;
		background-image: url('../assets/icons/closePlayground.png');
		background-repeat: no-repeat;
		background-size: contain;
		&:enabled {
			cursor: pointer;
		}
		&:disabled {
			opacity: 0.6;
		}
	}
	&__bar {
		position: relative;
		overflow: hidden;
		height: vars.$lesson-bar-height;
		display: flex;
		border-radius: 10px;
		border: 2px solid black;
		&__slider {
			position: absolute;
			top: 0;
			left: -50%;
			width: 100%;
			height: 100%;
			background-color: colors.$color19;
			transition: vars.$lesson-slider-transition;
		}
		&__divider {
			flex: 1;
			z-index: 10;
			border-right: 2px solid black;
			&:last-of-type {
				border-right: none;
			}
		}
	}
	&__section {
		box-sizing: border-box;
		width: 100%;
		min-width: 100%;
		height: auto;
		padding: 0 $lesson-padding;
		display: flex;
		justify-content: center;
		overflow: auto;
		&__card {
			display: grid;
			grid-template-rows: 58% 38%;
			gap: 0.5em;
			overflow: hidden;
			justify-content: center;
			align-items: center;
			width: 80%;
			height: auto;
			border-radius: 15px;
			&__one {
				display: flex;
				justify-content: center;
				width: 90%;
				max-width: 100%;
				height: auto;
			}
			&__two {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 0.5em;
				height: max-content;
				max-height: 100%;
				margin: 0;
				overflow: auto;
				align-self: self-end;
			}
			&__three {
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				width: 100%;
				overflow: auto;
				height: max-content;
				max-height: 100%;
				align-self: self-start;
			}
			// ----- COMPONENTS
			&__image {
				box-sizing: border-box;
				padding: $lesson-padding;
				display: flex;
				justify-content: center;
				max-width: 100%;
				max-height: 100%;
				aspect-ratio: $lesson-aspect-ratio;

				& > div {
					overflow: hidden;
					max-width: 100%;
					max-height: 100%;
					border-radius: 15px;
					border: 1px solid black;

					& > img {
						width: 100%;
						height: 100%;
					}
				}
			}
			&__text {
				background-color: #5a2522;
				color: #fff;
				width: auto;
				min-width: 8rem;
				border-radius: 10px;
				padding: .1em .5em;
				cursor: pointer;
				position: relative;
				font-size: 1.8rem;
				text-align: center; // ---------- COMPROBAR
				text-wrap: pretty;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				justify-content: center;
				gap: .5em;
				overflow: auto;
				height: max-content;
				max-height: 100%;

				& > div {
					height: 100%;
					word-break: break-word;
				}
			}
			&__translation {
				width: 100%;
				font-size: 1.6rem;
				text-align: center; // ---------- COMPROBAR
				pre {
					width: 100%;
					height: max-content;
					max-height: 100%;
					text-align: center;
					text-wrap: pretty;
					word-break: break-word;
				}
			}
		}
	}
	&__next {
		text-transform: capitalize;
		font-size: 1.1rem;
		padding: 0.5rem 3rem;
		border-radius: 10px;
		border: none;
		background-color: rgba($color: colors.$color18, $alpha: 0.9);
		color: white;
		&:enabled {
			cursor: pointer;
		}
		&:active {
			background-color: rgba($color: colors.$color18, $alpha: 1);
		}
		&:disabled {
			border-color: #888;
			background-color: rgba($color: colors.$color18, $alpha: 0.5);
		}
	}
}
