.crossword {
  display: grid;
  grid-template-columns: min-content 1fr;

  &PanelLeft {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &Container {
    display: grid;
    gap: 0.2em;
    justify-content: center;
    grid-template-rows: repeat(auto-fit, 3em);
  }
}
