@use './colors';

.audio__help__button {
	position: absolute;
	top: 0;
	right: 0;

	&__container {
		cursor: pointer;

		width: 50px;
		height: 50px;

		padding: 5px;

		border: 1px solid colors.$color5;
		border-radius: 6px;
		margin: 0.3rem;
		background-color: colors.$color2;

		&:hover {
			background-color: colors.$color4;
		}
		&:disabled {
			background-color: grey;
			opacity: 0.5;
		}

		&__image {
			width: 100%;
			height: 100%;
			max-width: 64px;
			max-height: 64px;
		}
	}
}
