@import 'variables';

.lost__items {
	display: flex;
	flex-direction: column;
	gap: 1.3rem;

	&__section {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 1rem;
		padding-bottom: 2rem;
		background-color: white;
		border-radius: 10px;

		&__header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: start;
			gap: 0.3rem;
			border-bottom: 1px solid #eee;
		}
	}
	&__item {
		display: flex;
		gap: 1rem;
		padding: 1rem;
		box-shadow: -1px 1px 10px 0px #888;
		border-radius: 10px;
		&__completed {
			box-shadow: none;
			background-color: rgba($color: #eee, $alpha: 0.3);
		}
		&__left {
			flex: 2;
		}
		&__right {
			flex: 8;
			display: flex;
			flex-direction: column;
			align-self: stretch;
		}
	}
	&__title {
		text-transform: uppercase;
		font-weight: 600;
	}
	&__location {
		font-size: 0.9rem;
		color: rgba($color: #000, $alpha: 0.8);
	}
	&__state {
		font-size: 0.9rem;
		width: max-content;
		padding: 0.2rem 0.5rem;

		border-radius: 10px;

		margin-top: auto;
		margin-bottom: 1rem;

		background-color: rgba($color: red, $alpha: 0.1);
		color: rgba($color: #000, $alpha: 0.8);

		&__founded {
			background-color: rgba($color: aqua, $alpha: 0.3);
		}
		&__completed {
			background-color: rgba($color: greenyellow, $alpha: 0.3);
		}
	}
	&__description {
		font-size: 1.1rem;
		margin-bottom: 0.5rem;
	}
	&__tip {
		font-size: 0.9rem;
		max-width: 60%;
		color: rgba($color: #000000, $alpha: 0.8);
		margin-bottom: 0.5rem;
	}
	&__image {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
		width: 100%;
		aspect-ratio: 1 / 1;
		border-radius: 10px;
		border: 1px solid grey;

		& > img {
			max-width: 100%;
			height: auto;
		}
		&__cover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.5);
			background-image: url('../assets/icons/interrogation.png');
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;

			&__founded {
				background-color: transparent;
				background-image: none;
			}
			&__completed {
				background-color: rgba($color: #fff, $alpha: 0.8);
				background-image: url('../assets/icons/completed2.png');
				background-size: 60%;
				opacity: 0.8;
			}
		}
	}
	&__reward {
		text-align: end;
		width: max-content;
		padding: 0.2rem 0.5rem;

		border-radius: 10px;

		margin-top: auto;
		margin-left: auto;

		background-color: #eee;
		&__completed {
			text-decoration: 2px line-through;
		}
	}
}
