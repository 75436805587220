@use './colors';

.sessionClosed {
	border: none;

	&Container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		display: flex;
		justify-content: center;
		align-items: center;

		background: linear-gradient(
			180.8deg,
			rgb(66, 255, 132) -0.8%,
			rgb(124, 216, 255) 99%
		);
		background: linear-gradient(
			135deg,
			rgb(121, 241, 237) 10%,
			rgb(14, 92, 173) 100%
		);

		&Card {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			width: 100%;
			max-width: 400px;
			height: 100%;
			max-height: 300px;

			padding: 1rem 2.5rem;

			border-radius: 26px;
			// box-shadow: 0 0 10px 0 rgba($color: colors.$color5, $alpha: 0.3);
			border: 2px solid rgba($color: colors.$color5, $alpha: 0.6);

			background-color: white;

			& > h2 {
				font-size: 2.5rem;
				text-align: center;
				opacity: 0.8;

				// font-size: 72px;
				background: -webkit-linear-gradient(
					rgb(10, 53, 98),
					colors.$color5
				);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			& > p {
				text-align: center;
				opacity: 0.7;
				padding: 1.5rem;
				// margin: 0 1.5rem;
				border-radius: 15px;
				background-color: rgba($color: colors.$color4, $alpha: 0.4);
				color: colors.$color5;
			}
			& > a {
				display: flex;
				justify-content: end;
				border: none;
				outline: none;
				text-decoration: none;
				& > div {
					width: min-content;
					padding: 0.5rem 3rem;
					border-radius: 10px;
					background-color: colors.$color5;
					color: white;
				}
			}
		}
	}
}
