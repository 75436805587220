@use './colors';
@use './utils';

.popup {
	@extend .black__background__center;

	&__window {
		display: flex;
		flex-direction: column;

		height: 95%;

		&__tabs {
			z-index: 20;
			position: relative;

			padding-left: 3rem;

			&__section {
				position: relative;
				display: flex;
				width: min-content;
				gap: 1rem;
				background-color: colors.$color18;
				border-radius: 10px 10px 0 0;
				padding: 0.5rem;
				padding-bottom: 0;
				margin-bottom: -0.8rem;

				&__tab {
					cursor: default;
					text-align: center;
					vertical-align: middle;
					padding: 0.4rem 0.5rem;
					border-radius: 6px;

					background-color: white;

					user-select: none;
					&__unselected {
						cursor: pointer;
						background-color: colors.$color19;
						color: #eee;
						&:hover {
							background-color: rgba(
								$color: colors.$color19,
								$alpha: 0.9
							);
						}
					}
				}
			}
		}
		&__bar {
			z-index: 10;
			position: sticky;
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 1rem;
			top: 0;
			border-radius: 10px 10px 0 0;
			padding: 0.5rem 0rem 0.5rem 0;
			background-color: colors.$color18;
			height: 50px;

			&__money {
				min-width: 8ch;
				padding: 0.3rem 1rem;

				border-radius: 10px;
				background-color: colors.$color26;
				color: colors.$color18;
			}
			&__close {
				cursor: pointer;
				position: absolute;
				top: -18px;
				right: -6px;

				position: relative;
				top: -18px;
				right: -6px;
				margin-right: -0.5rem;
				border: none;
				background-color: transparent;

				& > img {
					width: 56px;
					height: auto;
				}
			}
		}
		&__content {
			flex: 1;
			height: 0;
			border-radius: 0 0 10px 10px;
			padding: 1rem;
			padding-top: 0;
			background-color: rgba($color: #eee, $alpha: 1);
			background-color: colors.$color18;

			& > div {
				width: 100%;
				height: 100%;
				border-radius: 10px;
				overflow: auto;
				background-color: white;
			}
		}
	}
}
