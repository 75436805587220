@use './colors';

$anagram-shadow: 0 5px 30px 0 black;

.playground__anagram {
	margin: 0;
	box-sizing: border-box;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	&__words__wrapper {
		box-sizing: border-box;
		overflow: hidden;
		width: 100%;
		height: calc(40px + 16px); // 70px is the letter height
		&__container {
			box-sizing: border-box;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%; // -
			transition: top 400ms ease-in;
			padding: 8px 8px;
			&__word {
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				gap: 1vw;
				width: 100%; // -
				max-width: 100%;
				margin-bottom: 64px;
				overflow: visible;
				&__letter {
					box-sizing: border-box;
					font-size: 1em;
					font-weight: bold;

					display: flex;
					justify-content: center;
					align-items: center;
					flex-grow: 1;
					min-width: min-content;
					max-width: 40px;
					height: 40px;

					border-radius: 6px;
					background: colors.$color21;
					color: colors.$color19;

					outline: 2px solid colors.$color19;
					outline-offset: -2px;

					cursor: move;
					&__animation__color {
						animation: colorAnimation 400ms ease forwards;
					}
					&:hover {
						outline: 2px solid white;
						outline-offset: -2px;
					}
					&__ghost {
						background: white;
						color: white;
					}
					&__completed {
						background: blue;
					}
					&__disabled {
						background-color: colors.$color6;
						color: colors.$game-primary-disabled-text-color;
					}
				}
			}
		}
	}
}

#block__bar {
	width: 100%;
	height: 200px;
}

@keyframes timerDecrease {
	from {
		left: 0;
	}
	to {
		left: -100%;
	}
}

@keyframes colorAnimation {
	0% {
		transform: scale(1);
		background: colors.$color7;
	}
	50% {
		transform: scale(1.15);
	}
	100% {
		transform: scale(1);
		background: colors.$color8;
	}
}
