//COLORS
$primary-green: #208436;
$secondary-green: #7CC3A1;
$primary-blue: #346AB1;
$secondary-blue: #228BCB;
$primary-yellow: #E2BF2D;
$primary-red : #E55648;

$dark-orange: #AF2F08;
$orange: #FD631D;

$dark: #71C9CE;
$secondary-dark: #A6E3E9;
$secondary-white: #CBF1F5;
$primary-white: #fff;
$facebook: #039be5;
$twitter: #03a9f4;
$google: #f4511e;
$black: #1b1b1b;
$container-box-login: #145221;
$button-login: #A68B21;
$primary-gray: #a0a0a0;

$green-b1: #145221;
$green-b2: #208436;
$green-b3: #45CC8B;
$green-b4: #7CC3A1;
$green-b5: #9EE5BE;
$green-b6: #7CC2A0;

$yellow-b1: #A68B21;
$yellow-b2: #D3A40D;
$yellow-b3: #EFD35B;
$yellow-b4: #E8D074;
$yellow-b5: #FCE49A;

$blue-b1:#214573;
$blue-b2:#346AB1;
$blue-b3:#228BCB;
$blue-b4:#44B8F2;
$blue-b5:#95C2FC;
$blue-b6:#64BBCF;
$blue-b7: #00A3C4;

//ALIGNS
.center{
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    align-content: center;
    grid-template-columns: 1fr;
}
.left__text{
    display: grid;
    justify-content: center;
    justify-items: start;
    align-items: center;
    text-align: left;
    align-content: center;
    grid-template-columns: 1fr;
}
.right__text{
    display: grid;
    justify-content: center;
    align-items: end;
    text-align: right;
    align-content: center;
    grid-template-columns: 1fr;
}
.padding__left{
    left: 2px;
}

.padding__right{
    right: 5px;
}

//BTNS
.btn__green{
    background-color: transparent;
    background-image: url(../assets/images/btnGreen.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 5px 10px 30px 10px;
    width: 210px;
    padding: 12px;
    border: none;
    color: white;
    font-family: 'Philosopher', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
        opacity: .8;
        filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    }
}
.btn__yellow{
    background-color: transparent;
    background-image: url(../assets/images/Exitoyfallido/Botoncontinuaramarillo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 210px;
    padding: 20px;
    border: none;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
        opacity: .8;
        filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.7));
    }
}
.btn__red{
    background-color: transparent;
    background-image: url(../assets/images/Exitoyfallido/Botoncontinuarrojo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 20px;
    width: 210px;
    padding: 20px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
            opacity: .8;
            filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.7));
    }
}
.btn__purpple{
    background-color: transparent;
    background-image: url(../assets/images/Error404/Botonregresar.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 230px;
    padding: 20px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    filter: drop-shadow(0 1px 4px white);
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
            opacity: .8;
            filter: drop-shadow(0 1px 1px white);
    }
}
.btn__delete{
    background-color: $primary-red;
    border: none;
    border-radius: 10px;
    color: white;
    width: 35px;
    height: 35px;
    margin: 5px;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
            opacity: .8;
            filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.7));
    }
}
.btn__update{
    background-color: $yellow-b2;
    border: none;
    border-radius: 10px;
    color: white;
    width: 35px;
    height: 35px;
    margin: 5px;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
            opacity: .8;
            filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.7));
    }
}

//INPUTS
.select__styles{
    width: 74%;  
}
.inputs__styles{
    text-align: left ;
    width: 70%;
    border-radius: 5px;
    padding: 10px;
    border: none;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.24));
}

//CONTAINERS
.box {
	padding: 1px;
}

.btn__container {
	font-weight: bold;
	font-size: 1.2rem;
	margin: 15px;
	padding: 4px 20px 4px 25px;
	border-radius: 30px;
	background: $primary-yellow;
	box-shadow: 1px 1px 2px $yellow-b1;
	border: 1px solid $primary-yellow;
	color: $primary-white;
	cursor: pointer;
	opacity: 1;
	color: $primary-white;
	font-size: 1rem;
	&:hover {
		background: linear-gradient(145deg, $yellow-b4, $yellow-b3);
		box-shadow: 1px 1px 2px $yellow-b1;
		color: $primary-green;
	}
	&:disabled {
		background: $yellow-b3;
		box-shadow: 1px 1px 2px $yellow-b3;
		color: $primary-white;
	}
}

.modal__container {
	font-size: 1rem;
	margin-top: 20px;
	width: 95%;
	border-radius: 20px;
	@extend .center;
	@extend .box;
	grid-template-columns: 1fr 1fr;
	font-weight: 700;
}

.info__container {
	font-size: 1rem;
	margin-top: 15px;
	width: 80%;
	border-radius: 20px;
	@extend .center;
	@extend .box;
	grid-template-rows: 1fr 1fr;
	font-family: 'nonito', sans-serif;
}

.input__container {
	@extend .left__text;
	width: 95%;
	font-size: 0.7rem;
	border-radius: 50px;
	border: 1px solid $primary-gray;
	color: $black;
	background-color: $primary-gray;
	padding: 4px;
	font-family: 'Nunito Sans', sans-serif;
	margin-bottom: 10px;
}

.lbl__container {
	@extend .left__text;
	width: 90%;
	font-size: 0.8rem;
	margin-bottom: 3px;
	color: $black;
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 600;
}

.space__container {
	border-radius: 20px;
	background-image: url(../assets/images/bgContainer.svg);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
}

.title__container {
	margin: 10px auto;
	padding: 5px;
	width: 200px;
	font-size: 0.8rem;
}


//LINES
.green__line{
    padding: 5px;
    background-color: $green-b6;
    width: 80%;
    border-radius: 4px;
    border: 1px solid $green-b6;
}
.gray__line{
    padding: 5px;
    background-color: $primary-white;
    width: 80%;
    border-radius: 4px;
    border: 1px solid $primary-white;
}


//LINKS
.menu_link{
    font-weight: bold;
    text-decoration: none;
    margin-right: 25px;
    color: $primary-white;
    cursor: pointer;
    &:hover{
            color: $green-b5;
    }
}
.facebook__link{
    @extend .netwokrs__link;
    // background-image: url(../assets/icons/facebook.svg);
}
.facebook__footer__link{
    @extend .netwokrs__footer__link;
    // background-image: url(../assets/icons/facebook.svg);
    opacity: .7;
}
.footer_link{
    font-family: 'Philosopher', sans-serif;
    color: $primary-white;
    text-decoration: none;
    cursor: pointer;
}
.google__link{
    @extend .netwokrs__link;
    // background-image: url(../assets/icons/google.svg);
}
.google__footer__link{
    @extend .netwokrs__footer__link;
    // background-image: url(../assets/icons/google.svg);
    opacity: .7;
}
.twitter__footer__link{
    @extend .netwokrs__footer__link;
    // background-image: url(../assets/icons/Twitter.svg);
    opacity: .7;
}
.nav_link{
    cursor: pointer;
    margin-top: 20px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.netwokrs__link{
    cursor: pointer;
    @extend .center;
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    place-content: center;
    justify-content: center;
    align-items: center;
}
.netwokrs__footer__link{
    cursor: pointer;
    @extend .center;
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    place-content: center;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
}

.btnAdd{
    background-color: #8ec7e9;
    color: white;
    min-width: 100px;
    min-height: 50px;
    border: 2px solid #52acda8c;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    font-family: 'League Spartan', sans-serif;
    font-size: 1.3rem;
    font-weight: 300;
    &:hover{
        background: #a0d7f7;
        width: 90;
        height: 50px;
        border: 2px dashed #52acda8c;
        box-shadow: 0px 0px 5px rgb(141, 136, 136);
        text-shadow:  0px 0px 1px rgb(255, 255, 255);
    }
}



.btnDelete{
    margin: 10px;
    text-align: center;
    border: none;
    width: 50px;
    min-height: 50px;
    background: transparent;
    color: white;
    border-radius: 4px;
    transition: all 100ms;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover{
        background: #9DA9BD;
    }
}

.btnPerms{
    margin: 10px;
    text-align: center;
    border: none;
    width: 50px;
    min-height: 50px;
    background: transparent;
    color: white;
    border-radius: 4px;
    transition: all 100ms;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover{
        background: #9DA9BD;
    }
}

.btnEdit{
    margin: 10px;
    text-align: center;
    border: none;
    width: 50px;
    min-height: 50px;
    background: transparent;
    color: white;
    border-radius: 4px;
    transition: all 100ms;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover{
        background: #9DA9BD;
    }
}

.collapseStylesBtn{
    background-color: #f2f2f2;
    border: 2px solid #a7a9aa8c;
    padding: 5px;
    display: grid;
    cursor: pointer;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    position: relative;
    .arrowOpenCollapse{
        position: absolute;
        left: 10px;
        color: black;
    }
    .title{
        display: grid;
        font-weight: 400 !important;
        align-items: center;
        grid-template-columns: 1fr 0fr;
        margin-left: 10px;
        h2{
            margin-left: 25px;
            text-align: start;
            color: black;
            font-weight: 500 !important;
            p{
                display: inline;    
                font-size: 1.3rem;
                color: #c00d0db0;
            }
        }
        .error{
            color: #c00d0db0;
        }
    }
    .options{
        text-align: end !important;
        .btnDelete{
            font-size: 1.5rem !important;
            p{
                margin: 0px;
                color: #c00d0db0;
            }
            &:hover{
                transform: translateY(-5px);
                background-color: transparent;
            }
        }
        .btnEdit{
            font-size: 1.5rem !important;
            p{
                margin: 0px;
                color: #e4d339;
            }
            &:hover{
                transform: translateY(-5px);
                background-color: transparent;
            }
        }
    }
}
.collapseStylesBody{
    border-right: 2px solid #a7a9aa8c;
    border-left: 2px solid #a7a9aa8c;
    border-bottom: 2px solid #a7a9aa8c;
    border-radius: 0px 0px 10px 5px ;
    background-color: white;
    padding: 20px;
}

.searchCamp{
    width: 100%;
    &__input{
        width: 100%;
    }
}

.noSelect{
    text-align: center;
    color: #b40000;
    opacity: .5;
    font-weight: bold;
    font-size: 0.8rem;
}