@use './colors';
@use './utils';
$playground-button-size: 32px;

.playground__results {
	@extend .black__background__center;
	z-index: 2;
	border-radius: 15px;

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		gap: 0.5rem;

		width: 50%;
		min-width: 300px;
		height: 80%;
		padding: 1rem;
		border-radius: 15px;

		background-color: colors.$color26;

		& > h1 {
			font-size: 3rem;
			font-weight: 900;
			text-align: center;
			min-width: max-content;
			color: rgba($color: #000000, $alpha: 0.8);
		}
		&__image {
			background-color: colors.$color17;
		}
		&__stars {
			display: flex;
			gap: 0.5rem;
			height: calc(48px + 20px);

			&__star {
				width: 48px;
				height: 48px;

				&:nth-child(1) {
					margin-top: 20px;
				}
				&:nth-child(3) {
					margin-top: 20px;
				}

				&__black {
					filter: brightness(0) grayscale(1) saturate(3.3);
				}
				& > img {
					width: 100%;
					height: 100%;
				}
			}
		}
		& > p {
			text-align: center;
			font-weight: bold;
			color: colors.$color19;
		}
		&__buttons {
			width: 100%;
			display: flex;
			gap: 1rem;
			&__button {
				cursor: pointer;

				flex: 1;
				font-size: 1.1rem;
				font-weight: bold;
				height: $playground-button-size;

				border-radius: 10px;

				border: none;
				background-color: colors.$color19;
				color: white;
			}
			&__try__again {
				flex: 1;
				font-size: 1.1rem;
				height: $playground-button-size;
				margin-left: auto;
				border-radius: 10px;

				border: none;
				background-color: colors.$color19;
				color: white;

				&:enabled {
					cursor: pointer;
				}
			}
			&__complete {
				flex: 1;
				font-size: 1.1rem;
				font-weight: bold;
				height: $playground-button-size;

				border-radius: 10px;
				border: none;
				background-color: colors.$color19;
				color: white;
				&:enabled {
					cursor: pointer;
				}
			}
		}
	}
}
