.offcanvas {
	position: fixed;
	top: 0;
	bottom: 0;
	left: -380px;
	width: 350px;
	border-radius: 0px 10px 10px 0px;
	box-shadow: 1px 1px 30px 1px #646464;
	background-color: #6790a8;
	transition: left 0.2s ease-in-out;
	height: 100%;
	z-index: 100000000;
	&__titulo {
		margin-top: 20px;
		h1 {
			color: rgb(226, 226, 226);
			font-weight: 400 !important;
			text-shadow: 1px 1px 1px rgb(226, 226, 226);
			font-size: 2.7rem;
			margin: auto;
		}
		.closeButton {
			position: absolute;
			top: 10px;
			right: 10px;
			border: none;
			background-color: transparent;
			font-size: 1.5rem;
			color: rgb(226, 226, 226) !important;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	&__contenido {
		margin-top: 10px;
		display: grid;
		justify-content: center;
		gap: 20px;
		padding: 20px;
		color: white;

		.menuBtns {
			text-align: center;
			font-size: 1.1rem;
			border-radius: 0px 20px 20px 0px;
			margin: auto;
			width: 330px;
		}

		.divLabel {
			position: absolute;
			bottom: 20px;
			left: 0;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr;
			.menuBtnsFooter {
				margin: auto;
				font-size: 1.1rem;
				bottom: 10px;
				background-color: rgb(235, 121, 121);
				color: white;
				width: 70px;
				&:hover {
					background-color: rgb(255, 152, 152);
				}
			}
			label {
				margin: auto;
				padding: 10px;
				display: flex;
				align-items: center;
				font-size: 1.3rem;
				text-align: start;
				gap: 5px;
				color: #f2f2f2;
				font-weight: 400 !important;
				text-shadow: 1px 1px 1px #bbbbbb;
				.nameUser {
					font-size: 1.15rem;
					text-align: center;
					margin: 0;
				}
				.iconsUser {
					text-align: center;
					margin: 0;
				}
			}
		}
	}
}

.offcanvas.open {
	left: 0;
}

.offcanvas-offverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3); /* fondo semitransparente */
	z-index: 10000000;
}

.buttonBack {
	position: fixed;
	top: 10px;
	left: 80px;
	padding: 10px 20px;
	height: 40px;
	background-color: #fff;
	color: #3498db;
	border: 2px solid #3498db;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	svg {
		font-size: 20px;
	}
	&:hover {
		cursor: pointer;
		background-color: #3498db;
		color: #fff;
	}
}

.menuButton {
	@extend .buttonBack;
	top: 10px;
	left: 10px;
}

.gameLanguageButton {
	@extend .buttonBack;
	top: 10px;
	left: 150px;

	&Image {
		height: 30px;
		width: auto;
		margin: 0px 4px 0px 4px;
	}
}
