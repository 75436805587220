@use './utils';

.mobile__redirection {
	@extend .black__background__center;

	width: 100vw;
	height: 100dvh;

	padding: 1rem;

	flex-direction: column;
	justify-content: space-around;
	gap: 2.5rem;

	background-image: url('../assets/images/backgroundGlass.png');
	background-size: cover;

	& > h1 {
		font-weight: bold;
		text-align: center;
		font-size: 3rem;
	}

	&__buttons {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;

		& > a {
			width: 90%;

			border: none;
			background-color: transparent;

			& > img {
				width: 100%;
				height: auto;
				padding: 0;
			}
		}
	}
}
