@import 'variables.scss';

.boxLoadingIcon{
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    font-size: 1.6rem;
    text-align: center;
    .loading-icon{
        margin: auto;
        animation: rotate 4s linear infinite;
    }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }