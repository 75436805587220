.btnCrossword {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3rem;
	height: 3rem;
	background-color: #fedeca;
	border: 0.1px solid #702b0e;
	border-radius: 10px;
	box-shadow: 0px 6px 2px -2px #702b0e;
	font-size: 1.5rem;
	font-weight: 500;

	&:has(input:focus) {
		box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, .4);
		filter: saturate(1.2);
		transform: scale(1.001);
	}

	&Hidden {
		display: contents;

		& > .btnCrosswordInput {
			&::placeholder {
				color: transparent;
			}
		}
	}

	&Input {
		background: transparent;
		border: none;
		outline: none;
		width: 100%;
		text-align: center;
		font-size: 1.5em;

		&::placeholder {
			color: black;
		}
	}
	
	&Corrected {
		background: #a7e842;
	}

	&Wrong {
		background: #f41111;
	}
}
