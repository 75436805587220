@use './colors';

.playground__pairs {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&__section {
		box-sizing: inherit;
		flex-grow: 1;
		display: grid;
		grid-template-columns: 1fr min-content 1fr;
		grid-template-rows: minmax(min-content, 1fr);
		gap: 1rem;

		&__columnlines {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			& > div {
				// the line
				width: 2.8rem;
				height: 0.5rem;
				border-radius: 0.5rem;
				background-color: colors.$color22;
			}
		}
		&__column {
			box-sizing: inherit;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			&__card {
				flex: 1;
				font-weight: bold;
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;

				padding: 0.5rem 0;
				border-radius: 6px;

				background-color: colors.$color21;

				overflow: hidden;
				transition: opacity 250ms ease-in-out,
					background-color 250ms ease-in-out;

				&__selectable {
					border: 1px solid colors.$color19;
					background-color: colors.$color21;
					color: colors.$color19;
					cursor: pointer;
					&:hover {
						transition: none;
						background-color: colors.$color8;
					}
				}
				&__not__selectable {
					border: 1px solid colors.$color21;
					color: colors.$color22;
					background-color: transparent;
				}
				&__selected {
					border: 1px solid black;
					background-color: colors.$color8;
				}
				&__invisible {
					border: 1px solid black;
					opacity: 0.2;
				}
				&__wrong {
					border: 1px solid black;
					animation: PairsCardWrongAnimatio2 300ms ease-out,
						PairsCardWrongAnimation 300ms;
				}
				&__completed {
					opacity: 0.5;
					animation: PairsCardCompletedAnimation 500ms ease-out;
				}
				&__disabled {
					background-color: grey;
					opacity: 0.6;
				}
				&__content {
					display: flex;
					flex-direction: column;
					align-items: center;
					&__translation {
						font-size: 0.6rem;
					}
				}
			}
		}
	}
}

@keyframes PairsCardWrongAnimation {
	0% {
		transform: rotateZ(0deg);
	}
	25% {
		transform: rotateZ(-8deg);
	}
	50% {
		transform: rotateZ(0deg);
		background-color: red;
	}
	75% {
		transform: rotateZ(8deg);
	}
	100% {
		transform: rotateZ(0deg);
		background-color: colors.$color21;
	}
}

@keyframes PairsCardWrongAnimation2 {
	50% {
		transform: rotateZ(0deg);
		background-color: red;
	}
	100% {
		background-color: colors.$color21;
	}
}

@keyframes PairsCardCompletedAnimation {
	0% {
		transform: translateY(0px);
	}
	25% {
		transform: translateY(-20px);
	}
	50% {
		transform: translateY(0px);
	}
	75% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0px);
	}
}
