@use './colors';
@use './utils';

$controls-height: 9rem;

.tutorialsecuence {
	@extend .black__background__center;
	align-items: start;
	transition: opacity 150ms ease-out;

	&__main {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 2rem;

		&__animate {
			&__closetoconfig {
				transform: translate(calc(50vw - 35px), calc(-50vh + 35px))
					scale(0.1);
				transition: transform 800ms ease-out;
			}
			&__close {
				opacity: 0;
				transition: opacity 400ms ease-out;
			}
		}

		& img {
			max-width: 100%;
			height: 100%;
		}
		&__container {
			position: relative;
			width: 90%;
			height: 90%;

			&__card {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateX(0) translateY(0);

				&__info {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					gap: 3rem;
					padding: 4rem 3.5rem;
					overflow: auto;

					&__section {
						height: auto;
						display: flex;
						align-items: center;
						gap: 1.5rem;

						&__image {
							display: flex;
							justify-content: center;
							width: 25%;
							& > img {
								height: 100%;
							}
						}
						&__text {
							width: 75%;
							padding: .5rem;
							border-radius: 14px;
							border: 5px solid colors.$color19;
							background-color: colors.$color21;
							height: auto;
							text-align: center;
							text-wrap: pretty;
							font-size: 1.3rem;
						}
					}
				}
			}
			&__controls {
				width: calc(100% + 12rem);
				height: $controls-height;
				position: absolute;
				bottom: .5rem;
				left: -5rem;
				display: flex;
				gap: 1rem;

				&__text {
					overflow: auto;
					flex: 1;
					padding: 0 0.5rem 0 0.5rem ;
					border-radius: 14px;
					border: 5px solid colors.$color19;
					background-color: colors.$color21;
					font-size: 1.2rem;
				}
				&__button {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					align-self: center;
					cursor: pointer;
					font-weight: bold;
					width: 10%;
					height: 80%;
					aspect-ratio: 1 / 1;
					border-radius: 14px;
					border: 5px solid colors.$color19;
					padding: 0.5rem;
					background-color: colors.$color21;
				}
			}
		}
	}
}
