@import 'variables';

.delFile {
	color: #d60a0a !important;
	cursor: pointer;
	text-decoration: underline;
}
.modal {
	/* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
@media screen and (max-width: 1050px) {
	.modal-content {
		width: 80% !important;
	}
}
.modal-content {
	background-color: #f2f2f2;
	margin: 3% auto;
	border-radius: 5px;
	padding: 25px;
	border: 1px solid #888;
	width: 50%;
	.btnsManageUserDataTwo {
		margin: auto;
		margin-top: 20px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 3px;
		.btnAdd {
			@media (max-width: 1100px) {
				width: 100%;
			}
			margin: auto;
			display: flex;
			align-items: center;
			height: auto;
			justify-content: center;
			background-color: #40b67f;
			border: 2px solid #40b67f;
			text-align: center !important;
			margin-top: 10px;
			width: 60%;
			transition: all 0.2s;
			&:hover {
				background: #44d18f;
				width: 90;
				height: 50px;
				border: 2px dashed #2c8d60;
				box-shadow: 0px 0px 5px rgb(141, 136, 136);
				text-shadow: 0px 0px 3px rgb(255, 255, 255) !important;
			}
		}
	}
	.btnsManageUserData {
		@media (max-width: 1100px) {
			grid-template-columns: 1fr;
		}
		margin-top: 10px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 3px;
		.btnAdd {
			@media (max-width: 1100px) {
				width: 100%;
			}
			display: flex;
			align-items: center;
			height: auto;
			justify-content: center;
			background-color: #40b67f;
			border: 2px solid #40b67f;
			text-align: center !important;
			margin-top: 10px;
			width: 100%;
			transition: all 0.2s;
			&:hover {
				background: #44d18f;
				width: 90;
				height: 50px;
				border: 2px dashed #2c8d60;
				box-shadow: 0px 0px 5px rgb(141, 136, 136);
				text-shadow: 0px 0px 3px rgb(255, 255, 255) !important;
			}
		}
	}
	.btnsManageGroupData {
		margin-top: 10px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 3px;
		.btnAdd {
			@media (max-width: 1100px) {
				width: 100%;
			}
			margin: auto;
			display: flex;
			align-items: center;
			height: auto;
			justify-content: center;
			background-color: #40b67f;
			border: 2px solid #40b67f;
			text-align: center !important;
			margin-top: 10px;
			width: 100%;
			transition: all 0.2s;
			&:hover {
				background: #44d18f;
				width: 90;
				height: 50px;
				border: 2px dashed #2c8d60;
				box-shadow: 0px 0px 5px rgb(141, 136, 136);
				text-shadow: 0px 0px 3px rgb(255, 255, 255) !important;
			}
		}
	}
	.btnsManageGroupDataTwo {
		margin-top: 20px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 3px;
		.btnAdd {
			@media (max-width: 1100px) {
				width: 100%;
			}
			margin: auto;
			display: flex;
			align-items: center;
			height: auto;
			justify-content: center;
			background-color: #40b67f;
			border: 2px solid #40b67f;
			text-align: center !important;
			margin-top: 10px;
			width: 100%;
			transition: all 0.2s;
			&:hover {
				background: #44d18f;
				width: 90;
				height: 50px;
				border: 2px dashed #2c8d60;
				box-shadow: 0px 0px 5px rgb(141, 136, 136);
				text-shadow: 0px 0px 3px rgb(255, 255, 255) !important;
			}
		}
	}
	h2 {
		text-align: center;
		font-size: 25px;
		font-weight: bold !important;
		margin: 5px 0px 0px 0px;
		text-transform: uppercase;
	}
	.videoWorld {
		display: grid;
		justify-content: center;
		justify-items: center;
		margin-top: 30px;
		video {
			width: 100%;
		}
	}
	.admin__container__inputsColor {
		margin-top: 20px;
		display: grid;
		text-align: center;
		label {
			margin-top: 5px;
			margin-bottom: 5px;
			text-align: start;
			a {
				width: auto;
			}
		}
		.colorbox {
			display: grid;
			grid-template-columns: 1fr 5fr;
			justify-content: center;
			align-items: center;
			.admin__container__inputsColor__in {
				margin-top: 10px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 4px;
				border: 1px solid grey;
			}
			.backgroundColorBox {
				height: 65%;
				width: auto;
				margin: 10px 10px 10px 0px;
			}
		}
		&__title {
			text-align: start;
			font-size: 1.4rem;
			font-weight: bold;
		}
	}

	.admin__container__inputsCoordenadas {
		margin-top: 20px;
		display: grid;
		text-align: center;
		label {
			margin-top: 5px;
			margin-bottom: 5px;
			text-align: start;
			a {
				color: #000000;
				cursor: pointer;
			}
		}
		&__title {
			text-align: start;
			font-size: 1.4rem;
			font-weight: bold;
		}
		&__columns {
			@media (max-width: 1200px) {
				grid-template-columns: 1fr;
			}
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 5px;

			&__in {
				margin-top: 10px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 4px;
				border: 1px solid grey;
			}
		}
	}

	.admin__container__inputs {
		margin-top: 5px;
		display: grid;
		text-align: center;
		label {
			margin-top: 7px;
			text-align: start;
			font-size: 15px;
			a {
				color: #000;
				cursor: pointer;
			}
		}
		&__title {
			text-align: start;
			font-size: 1.4rem;
			font-weight: bold;
		}
		&__in {
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 10px;
			border-radius: 4px;
			border: 1px solid grey;
		}
	}
	.admin__container__boxBtn {
		text-align: center;
	}
	.admin__container__img {
		margin-top: 20px;
		overflow-x: scroll;
		img {
		}
	}
	.passwordRequeriments {
		display: grid;
		grid-template-columns: 1fr;
		text-align: center;
		p {
			margin-bottom: 0;
		}
		ul {
			color: #5f5b5b;
			justify-self: center;
			list-style: none;
			text-align: justify;
			width: max-content;
			padding: 0;
			li {
				display: flex;
				align-items: center;
				margin: 6px 0;
				label {
					display: flex;
					align-items: center;
					i {
						font-size: 1.3rem;
					}
				}
			}
		}
	}
}

.modalDelete {
	/* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
@media screen and (max-width: 1050px) {
	.modalDelete-content {
		width: 80% !important;
	}
}
.modalDelete-content {
	background-color: #f2f2f2;
	margin: 15% auto; /* 15% from the top and centered */
	border-radius: 10px;
	padding: 20px;
	border: 1px solid #888;
	width: 30%; /* Could be more or less, depending on screen size */
	h2 {
		text-align: center;
		font-size: 2rem;
		margin: 26px 0px 0px 0px;
	}
	p {
		text-align: center !important;
	}
	.content-btns {
		padding: 20px;
		text-align: center;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		.confirm {
			border-color: #c00d0db0;
			background-color: #c00d0db0;
		}
		.cancel {
			background-color: #888;
			border-color: #5f5b5b;
		}
	}
}

.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
