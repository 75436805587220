@use '../../vars';

.subscriptions {
	border: none;

	&Container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		display: flex;
		justify-content: center;
		align-items: center;

		background-color: rgba($color: black, $alpha: 0.4);

		&Card {
			overflow: auto;
			direction: rtl;
			width: 100%;
			max-width: 500px;
			min-width: 400px;
			height: 100%;
			max-height: 400px;

			padding: 2rem;

			border-radius: 20px;

			background-color: white;
			&Scroller {
				direction: ltr;
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 1rem;

				&Title {
					font-weight: bold;
					font-size: 1.2rem;
					color: rgba($color: vars.$color16, $alpha: 0.7);
				}
				&Section {
					overflow: hidden;
					cursor: pointer;
					display: flex;
					gap: 1rem;

					padding: 0.5rem 1.5rem;

					border-radius: 20px;
					border: 2px solid vars.$color11;
					&Selected {
						border: 2px dashed vars.$color1;
						background-color: rgba(
							$color: vars.$color1,
							$alpha: 0.1
						);
					}

					&FlagsContainer {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 25%;
						aspect-ratio: 1 / 1;
						// background-color: aquamarine;
					}
					&Content {
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 0.5rem;
						// background-color: bisque;

						&Title {
							font-size: 1.1rem;
							font-weight: bold;
							color: rgba($color: vars.$color2, $alpha: 0.6);
						}
						&Description {
							color: vars.$color11;
						}
						&SelectedTag {
							margin-top: auto;
							margin-left: auto;
							color: vars.$color1;
						}
					}
				}
			}
		}
	}
}
