@use '../../vars';

.companyBar {
	display: flex;
	align-items: center;
	gap: 1rem;

	padding: 0.8rem 3rem;
	border-bottom: 1px solid vars.$color12;

	&Brand {
		cursor: pointer;
		font-size: 1.5rem;
		display: flex;
		align-items: center;
		gap: 0.8rem;
		margin-right: auto;
		text-decoration: none;

		& > span {
			font-weight: 300;
			color: vars.$color5;
			& > span {
				font-weight: 700;
			}
		}
	}
	&Buttons {
		display: flex;
		gap: 2rem;
	}
	&Dropdown {
		z-index: 1000;
		position: relative;

		&Button {
			cursor: pointer;
			position: relative;

			&SubscriptionFlagsContainer {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 2rem;
				height: 2rem;
			}
		}
		&Container {
			overflow: auto;
			max-height: 90vh;
			// overflow: hidden;

			position: absolute;
			top: 140%;
			right: 0;

			border-radius: 8px;
			box-shadow: 0px 0px 12px 3px
				rgba($color: vars.$color11, $alpha: 0.5);

			background-color: white;
		}
	}
	&SubscriptionFlags {
		display: flex;
	}
	&AppsContainer {
		// overflow: hidden;
		border-radius: 8px;
		&Main {
			display: flex;
			margin: 0 0;

			border-radius: 8px;
			box-shadow: 0px 0px 12px 3px
				rgba($color: vars.$color11, $alpha: 0.5);
		}
		&Section {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			&:first-child {
				border-radius: 8px 0 0 8px;
			}
			&:last-child {
				border-radius: 0 8px 8px 0;
			}
			&Highlight {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 130%;
				&:hover {
					background-color: vars.$color5;
				}
				&Left {
					border-radius: 8px 0 0 8px;
				}
				&Right {
					border-radius: 0 8px 8px 0;
				}
			}
			&Icon {
				margin: 0.5rem 0.5rem;
			}
		}
	}
	&ProfileContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.3rem;
		width: 350px;

		&Image {
			padding: 2em 0 3em;

			&Container {
				position: relative;

				& > img {
					width: 100px;
					height: 100px;
					aspect-ratio: 1;
					display: block;
				}

				&Edit {
					position: absolute;
					bottom: 0;
					right: 0;
					color: vars.$color8;
					background: #e2e8fd;
					border-radius: 50%;
					padding: 0.5em;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					outline: none;
					border: none;

					&:hover {
						filter: brightness(1.1);
					}
				}
			}
		}

		&Data {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1em;
			margin: 1em 0;

			&User {
				width: 100%;
				cursor: pointer;

				&Container {
					max-height: 0;
					overflow: hidden;
					transition: max-height 0.2s ease-out;
					cursor: default;
				}

				&Button {
					display: grid;
					grid-template-columns: 40px 1fr 30px;
					align-items: center;
					gap: 1em;
					width: 100%;
					padding-left: 2em;
					padding-right: 0.5em;

					&Container {
						max-width: 30px;
						max-height: 30px;

						& > img {
							width: 100%;
							height: 100%;
							display: block;
							aspect-ratio: 1;
						}

						&Text {
							& > p {
								font-family: 'Poppins', monospace;
							}
						}

						&Arrow {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;

							&Rotate {
								& > svg {
									transform: rotate(90deg);
								}
							}

							& > svg {
								width: 15px !important;
								height: 15px;
								color: vars.$color5;
							}
						}
					}
				}
			}
		}

		&UpperBox {
			display: flex;
			justify-content: end;
			align-items: end;

			padding: 0.3rem;

			width: 100%;
			min-width: 200px;
			height: 56px; // set this size based on the profile image.

			background-color: vars.$color15;
		}
		// &Image {
		// overflow: hidden;
		// border-radius: 50%;
		// margin-top: -32px;
		// }
		&Text {
			font-size: 0.7rem;
		}
		&Subscription {
			cursor: pointer;
			font-size: 0.9rem;
			display: flex;
			justify-content: center;
			align-items: center;

			width: 100%;
			height: 2rem;
			margin-top: 0.5rem;
			padding: 0 0.5rem;

			background-color: vars.$color16;
			color: white;

			&Text {
				font-size: 0.8rem;
				padding: 0.5rem;
			}
			&Circle {
				display: flex;
				justify-content: center;
				align-items: center;

				width: 3rem;
				height: 3rem;

				padding: 0.4rem;

				border-radius: 50%;
				background-color: white;
			}
		}
		&Logout {
			width: 100%;

			&Button {
				cursor: pointer;
				display: flex;
				justify-content: center;
				width: 100%;
				padding: 1rem;
				background-color: vars.$color8;
				color: white;
				border: none;
				outline: none;
				font-family: 'Poppins', monospace;
				// font-weight: 500;
				font-size: 1.1em;
			}
		}
	}
	// &LanguagesContainer {
	//   min-width: 150px;
	//   &Section {
	//     cursor: pointer;
	//     display: flex;
	//     justify-content: space-between;
	//     align-items: center;
	//     padding: .5rem 1rem;

	//     &:hover {
	//       background-color: vars.$color5;
	//       color: white;
	//     }
	//   }
	// }
}

.formPersonalDataUser {
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding: 2em 3em 0.5em 2em;
	// padding-right: 3em;

	&Label {
		display: flex;
		flex-direction: column;
		gap: 0.3em;
		font-family: 'Poppins', monospace;

		& > input {
			border: none;
			outline: none;
			background: #e2e8fd;
			border-radius: 20px;
			padding: 0.3em 1em;
			font-size: 1em;
			font-family: inherit;
			width: 100%;
		}
	}

	&Container {
		&Errors {
			margin-top: 0.5em;

			& > p {
				font-family: 'Poppins', monospace;
				font-weight: 500;
				// font-size: 1em;
				color: red;
				text-align: center;
			}
		}

		&Success {
			margin-top: 0.5em;

			& > p {
				font-family: 'Poppins', monospace;
				font-weight: bold;
				font-size: 22px;
				color: green;
				text-align: center;
			}
		}

		&Loader {
			text-align: center;
			margin-top: 0.5em;
		}

		&Number {
			display: flex;
			// gap: .5em;
			width: 100%;
			padding-top: 0.3em;

			&Plus {
				color: vars.$color5;
				font-size: 1.5em;
				font-weight: 500;
				font-family: 'Poppins', monospace;
				padding-right: 0.1em;
			}

			& > input,
			select {
				border: none;
				outline: none;
				background: #e2e8fd;
				border-radius: 20px;
				padding: 0.3em 1em;
				font-size: 1em;
				font-family: 'Poppins', monospace;
			}

			& > input {
				width: 100%;
			}

			& > select {
				margin-right: 0.5em;
				padding: 0.3em 0.5em;
				width: fit-content;
				max-width: 100%;
			}
		}

		&Password {
			display: flex;
			align-items: center;
			gap: 0.5em;

			& > input {
				border: none;
				outline: none;
				background: #e2e8fd;
				border-radius: 20px;
				padding: 0.3em 1em;
				font-size: 1em;
				font-family: 'Poppins', monospace;
				width: 100%;
			}

			&ContainerPencil {
				background: vars.$color8;
				border: none;
				border-radius: 10px;
				outline: none;
				cursor: pointer;

				& > svg {
					display: flex;
					color: vars.$color4;
					padding: 0.35em;
					width: 35px;
					height: 30px;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	&ButtonSubmit {
		border: none;
		border-radius: 20px;
		outline: none;
		font-family: 'Poppins', monospace;
		font-size: 1.1em;
		color: vars.$color4;
		background: vars.$color8;
		padding: 0.5em 1.5em;
		width: fit-content;
		margin: 1em auto 0;
		box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
		cursor: pointer;

		&:disabled {
			opacity: 0.5;
			cursor: default;
		}
	}
}

.sectionContainerImage {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 1em;

	&Profile {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1em 0;
		padding: 1em 1.5em;
		max-height: 30em;
		overflow: auto;

		&User {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 100px;
			margin: 0 auto;

			& > img {
				width: 100%;
				height: 100%;
				display: block;
				aspect-ratio: 1;
				cursor: pointer;
				transition: transform 0.2s ease-in-out;

				&:hover {
					transform: scale(1.1);
					filter: contrast(1.5) drop-shadow(0px 0px 3px);
				}
			}

			&BlurImg {
				filter: blur(1px) grayscale(0.5);
			}
		}
	}
}

.containerSpinner {
	width: 100%;
	display: flex;
	justify-content: center;
}

#spinner {
	width: 35px;
	height: 35px;
	border: 5px solid blue;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner 1s linear infinite;
}

@media (orientation: portrait) {
	.companyBar {
		&Dropdown {
			position: initial;

			&Container {
				left: 2%;
				right: 2%;
				top: 10%;
			}
		}

		&ProfileContainer {
			width: auto;
		}
	}

	.formPersonalDataUser {
		&Container {
			&Number {
				& > select {
					width: 100%;
				}

				& > select[name='country_code'] {
					width: fit-content;
				}
			}

			&Password {
				width: 100%;

				& > input {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.companyBar {
		padding: 0.5em 2em 0.5em 1em;

		&Brand {
			font-size: 1.5rem;
		}

		&Buttons {
			gap: 1rem;
		}
	}
}

@keyframes spinner {
	from {
		transform: rotateZ(0deg);
	}
	to {
		transform: rotateZ(360deg);
	}
}
