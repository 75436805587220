@use './colors';

.black__background__center {
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba($color: black, $alpha: 0.6);
}

.animation {
	&__show {
		@keyframes show {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		animation: show 250ms ease-out forwards;
	}
	&__hide {
		@keyframes hide {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
		animation: hide 250ms ease-out forwards;
	}
}

.main__button {
	cursor: pointer;
	font-weight: bold;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 2.5rem;
	border: none;
	border-radius: 10px;

	background-color: colors.$color19;

	&:enabled {
		&:hover {
			background-color: colors.$color29;
			color: black;
		}
	}
	&:disabled {
		cursor: default;
	}
}
