@use './colors';

.tabs {
	z-index: 20;
	position: relative;

	padding-left: 3rem;

	&__section {
		position: relative;
		display: flex;
		width: min-content;
		gap: 1rem;
		background-color: colors.$color18;
		border-radius: 10px 10px 0 0;
		padding: 0.5rem;
		padding-bottom: 0;
		margin-bottom: -0.8rem;

		&__tab {
			cursor: default;
			text-align: center;
			vertical-align: middle;
			padding: 0.4rem 0.5rem;
			// min-width: 200px;
			border-radius: 6px;

			background-color: white;

			user-select: none;
			&__unselected {
				cursor: pointer;
				background-color: colors.$color19;
				color: #eee;
				&:hover {
					background-color: rgba(
						$color: colors.$color19,
						$alpha: 0.9
					);
				}
			}
		}
	}
}
