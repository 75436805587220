@import 'variables';

%alert{
    z-index: 1000000000000000000000000000000000000000000000000000000;
    width: 300px; 
    font-size: 1.2rem;
    color: #fff; 
    text-align: center; 
    border-radius: 20px; 
    padding: 16px; 
    position: fixed; 
    left: 10px;
    bottom: 80px;
    opacity: 1;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5s;
    animation: fadein 0.5s, fadeout 0.5s 5s;
}

#alerts__message__success {
    @extend %alert;
    background-color: $blue-b1;
}

#alerts__message__error {
    @extend %alert;
    background-color: #d21616;
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 80px; opacity: .9;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 80px; opacity: .9;}
}

@-webkit-keyframes fadeout {
    from {bottom: 80px; opacity: .9;}
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 80px; opacity: .9;}
    to {bottom: 0; opacity: 0;}
}